import * as React from 'react';
import { TransitionGroup } from 'react-transition-group-v1';
import { observer } from 'mobx-react';
import { CustomFieldViewModel, getCustomFieldOptionLength } from '../../utils/customFieldsUtils';
import { getCustomFieldPropertyMetadata } from '../../../Shared/utils/customFieldsValidation';
import { FundSelector } from './fund-selector';
import { SvgWrapper } from '../../components/svg-wrapper';
import { Models } from '../check-deposit-generated';
import CheckSplitEditModel = Models.CheckSplitEditModel;
import { injectValidationFormContext, IHaveValidatableFormContext } from '../../components/form-controls/validatable-form-context';

export interface IPaymentFundAllocationProps extends IHaveValidatableFormContext {
	splitInfo: CheckSplitEditModel[];
	viewModel: CustomFieldViewModel;
	allAmountsAreValid: boolean;
	singleFundOnly?: boolean;
	amountErrorMessage?: string;
	formattedTotalAllocated: string;
	maximumFundsLimit?: number;
	onFundChange: (fieldValue: string, index: number) => void;
	onAmountChange: (value: number, index: number) => void;
	onAmountBlur: () => void;
	onAdd: () => void;
	onDelete: (index: number) => void;
}

@injectValidationFormContext
@observer
export class FundAllocation extends React.Component<IPaymentFundAllocationProps, {}> {
	render() {
		const {splitInfo, onAmountChange, onFundChange, onAmountBlur, onDelete, viewModel, allAmountsAreValid, singleFundOnly,
			formattedTotalAllocated, amountErrorMessage, maximumFundsLimit} = this.props;
		const firstFund = `${viewModel.Key}_0`;
		const isMultiSplit = splitInfo.length > 1;
		const hasMaximumSplitItems = maximumFundsLimit > 0 && splitInfo.length >= maximumFundsLimit;
		const allFundsAssigned = splitInfo.length >= getCustomFieldOptionLength(viewModel);
		const showAddAnother = !allFundsAssigned && !singleFundOnly;
		const hasAmountError = amountErrorMessage && amountErrorMessage !== '';

		const maximumFundsHint = maximumFundsLimit && (
			hasMaximumSplitItems ? `Maximum of ${maximumFundsLimit} funds already added` : `Add up to ${maximumFundsLimit} funds`);

		return (
			<div className="row cd-fund-allocation">
				<div className="cd-fund-labels clearfix">
					<div className="cd-fund-label col-md-4">
						<label htmlFor={firstFund}>{viewModel.Label}</label>
					</div>
					{ isMultiSplit &&
						<div className="cd-amount-label col-md-3">
							<label htmlFor="Amount_0">Amount</label>
						</div>
					}
				</div>
				<TransitionGroup component="div" className="cd-fund-list clearfix">
					{
						splitInfo.map((x, index) =>
							<FundSelector
								key={`fund-${index}`}
								viewModel={viewModel}
								fund={x.Fund}
								fundErrorMessage={x.FundValidationErrorMessage}
								amount={x.Amount}
								amountErrorMessage={x.AmountValidationErrorMessage}
								onFundChange={onFundChange}
								onFundBlur={this.onFundBlur}
								onAmountChange={onAmountChange}
								onAmountBlur={onAmountBlur}
								onDelete={onDelete}
								isMultiSplit={isMultiSplit}
								index={index}
								ref={`fund-${index}`}
								allAmountsAreValid={allAmountsAreValid} />)
					}
				</TransitionGroup>
					<div className="cd-check-split-summary">
						<div className="col-md-2">
							{ showAddAnother &&
								<button type="button" className="cd-add-fund btn btn-link" onClick={this.addFundClick}
									disabled={hasMaximumSplitItems}
									title={maximumFundsHint}>
									<SvgWrapper svg="plus" className="icon" />
									Add another fund
								</button>
							}
						</div>
						{ isMultiSplit &&
							<div className="cd-check-split-total-amount col-md-4">
								<p className={hasAmountError ? null : 'cd-check-split-total-amount-success'}>
									Total allocated: {formattedTotalAllocated}
								</p>
							</div>
						}
					</div>
					{ isMultiSplit &&
						<div>
						<div className="cd-check-split-amount-validation-error col-md-6">
							{ hasAmountError &&
								<p>{amountErrorMessage}</p>
							}
						</div>
						<div className="cd-check-split-note col-md-12">
								(<strong>Note: </strong> This gift will be split between multiple transactions.This action cannot be undone.)
						</div>
					</div>
					}
			</div>
		);
	}

	private addFundClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		this.props.onAdd();
	}

	private onFundBlur = (): void => {
		const {splitInfo, validatableFormContext, viewModel} = this.props;
		const {propertyName} = getCustomFieldPropertyMetadata(viewModel);

		splitInfo.map((split, index) => {
			const name = `${propertyName}_${index.toString(10)}`;
			validatableFormContext.revalidateElement(name);
		});
	}
}
