import * as React from 'react';
import { observer } from 'mobx-react';
import { renderToStaticMarkup } from 'react-dom/server';
import { LinkType, PreconfiguredGivingLinkViewModel } from './preconfigured-giving-links-view-model';
import { Panel, PanelType, PanelHeader, PanelBody, PanelFooter } from '../components/panel';
import { Button } from '../../LoggedInWeb/components/button';
import { Fragment } from 'react';
import { ListingSelectorWithLabel } from '../components/listing-selector';
import { FormControlCheckbox } from '../components/form-controls/form-control-checkbox/form-control-checkbox';
import { FormControlLabelled, FormControlType } from '../components/form-controls/form-control-labelled';
import { IFormControlRadioOptionProps } from '../components/form-controls/form-control-radio';
import { SmoothHeightTransition } from '../components/hoc-behavior/transitions';
import {
	GiftType,
	FrequencyCode,
	RecurringEndType,
	FundVisibility,
	ModelMetadata,
	PaymentLabel,
	LanguageOptions,
	EmbeddedRedirectType,
} from './preconfigured-giving-links-generated';
import { ValidatableForm } from '../components/form-controls/validatable-form';
import { ValidationMessage } from '../components/form-controls/validation-message';
import { classNames } from '../../Shared/utils/classnames';
import { QrCodeViewer } from '../components/qr-code-viewer';
import { ScrollToTopButton } from '../components/scroll-to-top-button';
import * as styles from './index.less';
import {
	GeneratedResultScrollContainer,
	PreconfiguredGivingLinksResultScrollContainer,
} from './preconfigured-giving-links-result-scroll-container';
import { GivingFrequencySelectorWithLabel } from '../components/giving-frequency-selector';
import { EmbeddedFormGenerator, getEmbeddedScript } from '../components/embedded-form';

@observer
export class PreconfiguredGivingLinks extends React.Component<{
	vm: PreconfiguredGivingLinkViewModel;
}> {
	render() {
		const { paymentLabel, embeddedGivingFormGenerationFlag} = this.props.vm;

		if (embeddedGivingFormGenerationFlag) {
			const givingProgressiveForm = this.getGivingWordInProgressiveForm(paymentLabel);
			const noMerchantConfiguredMessaging =
				'You need a valid merchant configured for Web Giving to use this generator.';
			const helpUrl = this.props.vm.preconfiguredGivingLinkHelpUrl;
			const embeddedHelpUrl = this.props.vm.embeddedWidgetHelpUrl

			return (
				<div>
					<h1 className="page-title">{givingProgressiveForm} Links & Codes</h1>
					<p className={styles.preconfiguredGivingPageDescription}>
						Generate a <a href={helpUrl}>{givingProgressiveForm.toLowerCase()} link & QR code</a>, or an
						 <a href={embeddedHelpUrl}> embedded {givingProgressiveForm.toLowerCase()} widget</a> to pre-populate
						certain fields for your donor. These are great for targeted campaigns, like{' '}
						{givingProgressiveForm.toLowerCase()} drives for specific funds.
					</p>
					<ValidatableForm onSubmit={this.handleSubmit} validationErrors={this.props.vm.validationErrors}>
						{this.renderLayout(noMerchantConfiguredMessaging)}
					</ValidatableForm>
				</div>
			);
		} else {
			const givingProgressiveForm = this.getGivingWordInProgressiveForm(paymentLabel);
			const noMerchantConfiguredMessaging =
				'You need a valid merchant configured for Web Giving to use this generator.';
			const helpUrl = this.props.vm.preconfiguredGivingLinkHelpUrl;

			return (
				<div>
					<h1 className="page-title">{givingProgressiveForm} Links & Codes</h1>
					<p className={styles.preconfiguredGivingPageDescription}>
						Generate a <a href={helpUrl}>{givingProgressiveForm.toLowerCase()} link & QR code</a> to
						pre-populate certain fields for your {paymentLabel.PayerPluralLowerCase}! These are great for
						targeted campaigns, like {givingProgressiveForm.toLowerCase()} drives for specific funds.
					</p>
					<ValidatableForm onSubmit={this.handleSubmit} validationErrors={this.props.vm.validationErrors}>
						{this.renderLayoutWithQrCodeSupport(givingProgressiveForm, noMerchantConfiguredMessaging)}
					</ValidatableForm>
				</div>
			);
		}
	}

	private renderLayout(noMerchantConfiguredMessaging: string) {
		const {
			listings,
			generatedUrl,
			generatedQrCodeUrl,
			isGeneratingQrCode,
			isEmbeddedMode,
			linkType,
			formGenerationInfo,
			embeddedGivingFormGenerationFlag,
		} = this.props.vm;

		const hasQROrUrl = Boolean(generatedUrl) || Boolean(generatedQrCodeUrl);
		const hasForm = Boolean(formGenerationInfo);

		return (
			<Panel
				panelType={PanelType.DEFAULT}
				extraClassNames={classNames(
					styles.preconfiguredGivingPanel,
					!isEmbeddedMode && !hasQROrUrl && styles.preconfiguredGivingPanelNoGeneratedUrls,
					isEmbeddedMode && !hasForm && styles.preconfiguredGivingPanelNoGeneratedUrls
				)}
			>
				<PanelHeader>Generate Links & Codes</PanelHeader>
				{listings && listings.length ? (
					<>
						<PanelBody extraClassNames={styles.preconfiguredGivingPanelBody}>
							<div className="row">
								<div className={styles.linkTypeContainer}>
									<label>Type</label>
									<div className={styles.linkTypeBtns}>
										<Button
											className={classNames(
												'btn',
												styles.linkTypeBtn,
												linkType === LinkType.PCGLAndQRCode && styles.linkTypeBtnActive
											)}
											onClick={this.handleSetLinkTypeToPCGL}
										>
											{this.props.vm.paymentLabel.ActionSentenceCase} Link & QR Code
										</Button>
										<Button
											className={classNames(
												'btn',
												styles.linkTypeBtn,
												linkType === LinkType.EmbeddedForm && styles.linkTypeBtnActive
											)}
											onClick={this.handleSetLinkTypeToEmbedded}
										>
											Embedded Widget
										</Button>
									</div>
								</div>
							</div>
							<div className="row">
								<div className={classNames('col-lg-6', styles.preconfiguredGivingPanelContentLeft)}>
									{this.renderGenerationForm(embeddedGivingFormGenerationFlag, isEmbeddedMode)}
								</div>
								<div className={styles.preconfiguredGivingMobileCta}>
									<Button
										isProcessingRequest={isGeneratingQrCode}
										submit={true}
										className={classNames(
											'btn',
											'btn-default',
											styles.preconfiguredGivingMobileCtaBtn
										)}
									>
										{isGeneratingQrCode ? 'Generating' : 'Generate'}
									</Button>
								</div>
								<div
									className={classNames(
										'col-lg-6',
										styles.preconfiguredGivingPanelContentRight,
										!isEmbeddedMode &&
											!generatedUrl &&
											styles.preconfiguredGivingPanelNoGeneratedUrls,
										isEmbeddedMode && !hasForm && styles.preconfiguredGivingPanelNoGeneratedUrls
									)}
								>
									{this.renderGenerationResult(isEmbeddedMode)}
								</div>
							</div>
						</PanelBody>
						<PanelFooter extraClassNames={styles.preconfiguredGivingDesktopCta}>
							<Button
								disabled={
									this.props.vm.formGenerationInfo && this.props.vm.linkType === LinkType.EmbeddedForm
								}
								isProcessingRequest={isGeneratingQrCode}
								submit={true}
								className="btn btn-default"
							>
								{isGeneratingQrCode ? 'Generating' : 'Generate'}
							</Button>
						</PanelFooter>
					</>
				) : (
					<PanelBody extraClassNames={styles.preconfiguredGivingPanelBody}>
						{noMerchantConfiguredMessaging}
					</PanelBody>
				)}
			</Panel>
		);
	}

	private renderLayoutWithQrCodeSupport(givingProgressiveForm: string, noMerchantConfiguredMessaging: string) {
		const { listings, generatedUrl, generatedQrCodeUrl, isGeneratingQrCode, embeddedGivingFormGenerationFlag } = this.props.vm;
		return (
			<Panel
				panelType={PanelType.DEFAULT}
				extraClassNames={classNames(
					styles.preconfiguredGivingPanel,
					!generatedUrl && !generatedQrCodeUrl && styles.preconfiguredGivingPanelNoGeneratedUrls
				)}
			>
				<PanelHeader>Generate {givingProgressiveForm} Link & QR Code</PanelHeader>
				{listings && listings.length ? (
					<>
						<PanelBody extraClassNames={styles.preconfiguredGivingPanelBody}>
							<div className="row">
								<div className={classNames('col-lg-6', styles.preconfiguredGivingPanelContentLeft)}>
									{this.renderGenerationForm(embeddedGivingFormGenerationFlag)}
								</div>
								<div className={styles.preconfiguredGivingMobileCta}>
									<Button
										isProcessingRequest={isGeneratingQrCode}
										submit={true}
										className={classNames(
											'btn',
											'btn-default',
											styles.preconfiguredGivingMobileCtaBtn
										)}
									>
										{isGeneratingQrCode ? 'Generating' : 'Generate'}
									</Button>
								</div>
								<div
									className={classNames(
										'col-lg-6',
										styles.preconfiguredGivingPanelContentRight,
										generatedUrl ? '' : styles.preconfiguredGivingPanelNoGeneratedUrls
									)}
								>
									{this.renderGenerationResult()}
								</div>
							</div>
						</PanelBody>
						<PanelFooter extraClassNames={styles.preconfiguredGivingDesktopCta}>
							<Button isProcessingRequest={isGeneratingQrCode} submit={true} className="btn btn-default">
								{isGeneratingQrCode ? 'Generating' : 'Generate'}
							</Button>
						</PanelFooter>
					</>
				) : (
					<PanelBody extraClassNames={styles.preconfiguredGivingPanelBody}>
						{noMerchantConfiguredMessaging}
					</PanelBody>
				)}
			</Panel>
		);
	}

	private renderGenerationForm( embeddedGivingFormGenerationFlag: boolean, isEmbeddedMode?: boolean) {
		if (embeddedGivingFormGenerationFlag) {
			if (isEmbeddedMode) {
				return (
					<Fragment>
						{this.renderListingSelector()}
						{this.renderEmbeddedRedirectOptions()}
					</Fragment>
				);
			} else {
				return (
					<Fragment>
						{this.renderListingSelector()}
						{this.props.vm.funds && this.props.vm.funds.length > 0 && this.renderFundOptions()}
						{this.renderAmountInputField()}
						{this.renderFixedAmountCheckbox()}
						{this.renderGiftType()}
						{this.renderRecurringVisibility(this.props.vm.paymentLabel)}
						{this.props.vm.languageSelectorIsEnabled() && this.renderLanguageSelector()}
						{this.renderNotes()}
					</Fragment>
				);
			}
		} else {
			return (
				<Fragment>
					{this.renderListingSelector()}
					{this.props.vm.funds && this.props.vm.funds.length > 0 && this.renderFundOptions()}
					{this.renderAmountInputField()}
					{this.renderFixedAmountCheckbox()}
					{this.renderGiftType()}
					{this.renderRecurringVisibility(this.props.vm.paymentLabel)}
					{this.props.vm.languageSelectorIsEnabled() && this.renderLanguageSelector()}
					{this.renderNotes()}
				</Fragment>
			);
		}
	}

	private renderGenerationResult(isEmbeddedMode?: boolean) {
		const {
			formGenerationInfo,
			generatedUrl,
			generatedQrCodeUrl,
			isGeneratingQrCode,
			onQrCodeGenerationComplete,
			paymentLabel,
			embeddedGivingFormGenerationFlag,
		} = this.props.vm;

		if (embeddedGivingFormGenerationFlag) {
			if (isEmbeddedMode) {
				const hasFormData = Boolean(formGenerationInfo);
				return (
					<GeneratedResultScrollContainer shouldScroll={hasFormData}>
						<div className={classNames(!hasFormData && styles.preconfiguredGivingNoGeneratedQrCodeUrl)}>
							{this.renderEmbeddedFormOutputField()}
						</div>
						<div className={classNames(!hasFormData && styles.preconfiguredGivingNoGeneratedQrCodeUrl)}>
							<ScrollToTopButton cssClassNames={styles.preconfiguredGivingScrollToTopButton} />
						</div>
					</GeneratedResultScrollContainer>
				);
			} else {
				return (
					<GeneratedResultScrollContainer shouldScroll={Boolean(generatedUrl)}>
						<div className={classNames('row', !generatedUrl && styles.preconfiguredGivingNoGeneratedUrl)}>
							{this.renderGivingLinkOutputField()}
						</div>
						<div
							className={classNames(
								'row',
								!generatedQrCodeUrl && styles.preconfiguredGivingNoGeneratedQrCodeUrl
							)}
						>
							<QrCodeViewer
								onQrCodeGenerationComplete={onQrCodeGenerationComplete}
								isGeneratingQrCode={isGeneratingQrCode}
								qrCodeUrl={generatedQrCodeUrl}
								downloadFileName={this.getQrCodeDownloadFileName()}
								viewerOptions={this.renderQrCodeLogoDisplayOptions()}
								tooltipOptions={{
									message: `Download and print your QR code to provide your ${paymentLabel.PayerPluralLowerCase} an alternative way to give`,
								}}
							/>
						</div>
						<div className="row">
							<ScrollToTopButton cssClassNames={styles.preconfiguredGivingScrollToTopButton} />
						</div>
					</GeneratedResultScrollContainer>
				);
			}
		} else {
			return (
				<PreconfiguredGivingLinksResultScrollContainer generatedUrl={generatedUrl}>
					<div className={classNames('row', !generatedUrl && styles.preconfiguredGivingNoGeneratedUrl)}>
						{this.renderGivingLinkOutputField()}
					</div>
					<div
						className={classNames(
							'row',
							!generatedQrCodeUrl && styles.preconfiguredGivingNoGeneratedQrCodeUrl
						)}
					>
						<QrCodeViewer
							onQrCodeGenerationComplete={onQrCodeGenerationComplete}
							isGeneratingQrCode={isGeneratingQrCode}
							qrCodeUrl={generatedQrCodeUrl}
							downloadFileName={this.getQrCodeDownloadFileName()}
							viewerOptions={this.renderQrCodeLogoDisplayOptions()}
							tooltipOptions={{
								message: `Download and print your QR code to provide your ${paymentLabel.PayerPluralLowerCase} an alternative way to give`,
							}}
						/>
					</div>
					<div className="row">
						<ScrollToTopButton cssClassNames={styles.preconfiguredGivingScrollToTopButton} />
					</div>
				</PreconfiguredGivingLinksResultScrollContainer>
			);
		}
	}

	private renderGivingLinkOutputField() {
		const { paymentLabel } = this.props.vm;
		const givingProgressiveForm = this.getGivingWordInProgressiveForm(paymentLabel);
		return (
			<FormControlLabelled
				label={`${givingProgressiveForm} Link`}
				formControlProps={{
					name: 'GivingLinkResult',
					value: this.props.vm.generatedUrl,
					formControlType: FormControlType.Clipboard,
					placeholder: 'Link will be displayed once generated below',
					cssClassNames: styles.preconfiguredGivingClipboardInput,
				}}
			/>
		);
	}

	getGivingWordInProgressiveForm = (paymentLabel: PaymentLabel) => {
		switch (paymentLabel.VerbSentenceCase) {
			case 'Pay':
				return paymentLabel.NounSentenceCase;
			case 'Donate':
				return paymentLabel.NounSentenceCase;
			case 'Give':
			default:
				return paymentLabel.VerbPresentTenseSentenceCase;
		}
	};

	private getQrCodeDownloadFileName = () => {
		const { selectedListing, selectedFundName, giftTypeString, language } = this.props.vm;
		const fundText = !!selectedFundName ? `${selectedFundName}_` : ``;
		const languageCode = !!language ? `_${language.TwoLetterIsoCode}` : ``;

		return `${selectedListing}_${fundText}${giftTypeString}${languageCode}`;
	};

	private handleSubmit = () => {
		this.props.vm.isEmbeddedMode ? this.props.vm.generateEmbeddedForm() : this.props.vm.generateUrlWithQrCodeLink();
	};

	private renderListingSelector() {
		return (
			<ListingSelectorWithLabel
				childrenClassName={''}
				singleLine={false}
				labelForListingSelection="Listing"
				onChange={this.handleListingChange}
				selectedListingId={`${this.props.vm.selectedListing === null ? '' : this.props.vm.selectedListing}`}
				availableListings={this.props.vm.getAvailableListingOptions()}
				isDisabled={this.isDisabledStateWhileGenerating()}
			/>
		);
	}

	private renderFundOptions() {
		var forListingOptions = [];
		var allFundsOptions = [];

		this.props.vm.funds.forEach((x) =>
			x.ForListing
				? forListingOptions.push({
						Label: x.FundName,
						Value: x.FundKey,
				  })
				: allFundsOptions.push({ Label: x.FundName, Value: x.FundKey })
		);

		return (
			<Fragment>
				<div className={'row'} style={{ paddingTop: 27 }}>
					<FormControlLabelled
						label="Fund"
						formControlProps={{
							name: ModelMetadata.PreconfiguredGivingLinkGenerationRequest.FundName.propertyName,
							placeholder: 'Select Fund',
							value: this.props.vm.selectedFund,
							onChangeHandler: this.handleSelectedFundChange,
							formControlType: FormControlType.DropDown,
							disabled: this.isDisabledStateWhileGenerating(),
							Options: [
								{
									Label: 'For Listing',
									Options: forListingOptions,
								},
								{
									Label: 'All Funds',
									Options: allFundsOptions,
								},
							],
						}}
					/>
				</div>

				{this.renderFundVisibility()}
			</Fragment>
		);
	}

	private getFundVisibilityTooltipContent = () => (
		<div className={styles.preconfiguredGivingFundTooltipContent}>
			<p>
				<strong>Default:</strong> Display the fund selection box.
			</p>
			<p>
				<strong>Hide:</strong> Remove fund selection box from the page entirely.
			</p>
			<p>
				<strong>Lock:</strong> Display the selected fund as a read-only value.
			</p>
		</div>
	);

	private renderFundVisibility() {
		var isDisabled = true;

		isDisabled = !this.props.vm.selectedFund || this.isDisabledStateWhileGenerating();

		return (
			<div className={classNames('row', styles.preconfiguredGivingFundVisibility)}>
				<SmoothHeightTransition>
					<FormControlLabelled
						label="Fund Visibility"
						formControlProps={{
							name: ModelMetadata.PreconfiguredGivingLinkGenerationRequest.FundVisibility.propertyName,
							formControlType: FormControlType.Radio,
							value: `${this.props.vm.fundVisibility}`,
							onChangeHandler: this.handleFundVisibilityChange,
							disabled: isDisabled,
							Options: [
								{
									Label: 'Default',
									Value: `${FundVisibility.Default}`,
								},
								{
									Label: 'Hide',
									Value: `${FundVisibility.Hide}`,
								},
								{
									Label: 'Lock',
									Value: `${FundVisibility.Lock}`,
								},
							],
							acceptanceTestTargetId: 'pcgl-fund-visibility',
							radioItemClassName: styles.preconfiguredGivingRadioButton,
						}}
						tooltipOptions={{
							className: styles.preconfiguredGivingFundTooltipContainer,
							message: this.getFundVisibilityTooltipContent(),
						}}
					/>
				</SmoothHeightTransition>
			</div>
		);
	}

	private renderAmountInputField() {
		return (
			<Fragment>
				<div className={'row'} style={{ paddingTop: 13 }}>
					<FormControlLabelled
						label="Amount"
						formControlProps={{
							name: ModelMetadata.PreconfiguredGivingLinkGenerationRequest.Amount.propertyName,
							formControlType: FormControlType.Amount,
							value: this.props.vm.amount,
							onChangeHandler: this.onAmountChange,
							isInvalid: this.props.vm.amountIsInvalid ? true : false,
							placeholder: '0.00',
							validationRules:
								ModelMetadata.PreconfiguredGivingLinkGenerationRequest.Amount.validationRules,
							acceptanceTestTargetId: 'pcgl-amount',
							disabled: this.isDisabledStateWhileGenerating(),
						}}
					/>
					<ValidationMessage
						elementName={ModelMetadata.PreconfiguredGivingLinkGenerationRequest.Amount.propertyName}
					/>
				</div>
			</Fragment>
		);
	}

	private renderFixedAmountCheckbox() {
		var isDisabled = true;

		isDisabled = !this.props.vm.amount || this.isDisabledStateWhileGenerating();

		return (
			<FormControlCheckbox
				value={this.props.vm.isFixedAmount}
				onChangeHandler={this.handleIsFixedAmountChange}
				text="Set Fixed Amount"
				disabled={isDisabled}
				acceptanceTestTargetId="pcgl-amount-lock"
			/>
		);
	}

	private renderGiftType() {
		const listing = this.props.vm.listings.filter((l) => l.ListingHandle === this.props.vm.selectedListing)[0];

		return (
			<Fragment>
				<div className={classNames('row', styles.preconfiguredGivingGiftTypeRadioGroup)}>
					<SmoothHeightTransition>
						<FormControlLabelled
							label={`${this.props.vm.paymentLabel.NounSentenceCase} Type`}
							formControlProps={{
								name: ModelMetadata.PreconfiguredGivingLinkGenerationRequest.GiftType.propertyName,
								formControlType: FormControlType.Radio,
								value: `${this.props.vm.giftType}`,
								onChangeHandler: this.handleGiftTypeChange,
								Options: [
									{
										Label: `Listing Default (${
											listing && listing.ListingGiftRecurringByDefault ? 'Recurring' : 'One Time'
										})`,
										Value: `${GiftType.Default}`,
									},
									{
										Label: 'One Time',
										Value: `${GiftType.Single}`,
									},
									{
										Label: 'Recurring',
										Value: `${GiftType.Recurring}`,
										radioButtonClassName:
											this.props.vm.giftType === GiftType.Recurring &&
											classNames('notch', styles.preconfiguredGivingGiftTypeRadioRecurringActive),
									},
								],
								acceptanceTestTargetId: 'pcgl-gift-type',
								radioItemClassName: styles.preconfiguredGivingRadioButton,
								disabled: this.isDisabledStateWhileGenerating(),
							}}
						/>
					</SmoothHeightTransition>
				</div>
				{this.props.vm.giftType === GiftType.Recurring && this.renderRecurringOptions()}
			</Fragment>
		);
	}

	private renderRecurringVisibility(paymentLabel: PaymentLabel) {
		return (
			<FormControlCheckbox
				value={this.props.vm.recurringVisibility}
				onChangeHandler={this.handleRecurringVisibilityChange}
				text={`Set ${paymentLabel.NounLowerCase} type visibility`}
				disabled={this.isDisabledStateWhileGenerating()}
				acceptanceTestTargetId="pcgl-recurring-visibility"
				tooltipOptions={{
					message: this.getRecurringVisibilityTooltipContent(this.props.vm.paymentLabel),
				}}
			/>
		);
	}

	private getRecurringVisibilityTooltipContent = (paymentLabel: PaymentLabel) => {
		return (
			<div className={styles.preconfiguredGivingRecurringVisibilityTooltipContent}>
				<p>
					Keep this box checked to give your {paymentLabel.PayerPluralLowerCase} the option to toggle between
					a one time or recurring {paymentLabel.NounLowerCase}. If this box is unchecked,{' '}
					{paymentLabel.PayerPluralLowerCase} will not be able to change the {paymentLabel.NounLowerCase}{' '}
					type.
				</p>
			</div>
		);
	};

	private renderRecurringOptions() {
		return (
			<Fragment>
				<SmoothHeightTransition>
					<div
						className={classNames(
							'panel-expander',
							styles.preconfiguredGivingGiftTypeRecurrenceConfigurator
						)}
					>
						<div className="row">
							<GivingFrequencySelectorWithLabel
								acceptanceTestTargetId={'pcgl-recurring-frequency'}
								labelForGivingFrequencySelection={'How often?'}
								selectedFrequencyValue={`${this.props.vm.frequencyCode}`}
								availableFrequencies={this.getRecurringFrequencyOptions()}
								onChange={this.handleRecurringFrequencyChange}
								isDisabled={this.isDisabledStateWhileGenerating()}
							/>
						</div>
						<div className="row">
							<FormControlLabelled
								label="Start Date"
								formControlProps={{
									name: 'recurring-start-date',
									formControlType: FormControlType.DatePicker,
									placeholder: 'Choose a date',
									value: this.props.vm.recurringStartDate,
									onChangeHandler: this.onStartDateChange,
									options: {
										minDate: this.props.vm.recurringMinStartDate,
										disableDayFn: this.disableDays,
									},
									validationRules:
										ModelMetadata.PreconfiguredGivingLinkGenerationRequest.StartDate
											.validationRules,
									acceptanceTestTargetId: 'pcgl-recurring-start-date',
									disabled: this.isDisabledStateWhileGenerating(),
								}}
							/>
						</div>

						{this.props.vm.payerSpecificRecurringEndDateEnabled && this.renderRecurringEndTypeOptions()}
					</div>
				</SmoothHeightTransition>
			</Fragment>
		);
	}

	private getRecurringFrequencyOptions() {
		const { availableRecurringFrequencies } = this.props.vm;
		let recurringFrequencyOptions: IFormControlRadioOptionProps[] = [];

		if (availableRecurringFrequencies.indexOf(FrequencyCode.Weekly) >= 0) {
			recurringFrequencyOptions.push({
				Label: 'Every week',
				Value: `${FrequencyCode.Weekly}`,
			});
		}
		if (availableRecurringFrequencies.indexOf(FrequencyCode.Fortnightly) >= 0) {
			recurringFrequencyOptions.push({
				Label: 'Every 2 weeks',
				Value: `${FrequencyCode.Fortnightly}`,
			});
		}
		if (availableRecurringFrequencies.indexOf(FrequencyCode.Monthly) >= 0) {
			recurringFrequencyOptions.push({
				Label: 'Every month',
				Value: `${FrequencyCode.Monthly}`,
			});
		}

		if (availableRecurringFrequencies.indexOf(FrequencyCode.FirstAndFifteenth) >= 0) {
			recurringFrequencyOptions.push({
				Label: '1st and 15th monthly',
				Value: `${FrequencyCode.FirstAndFifteenth}`,
			});
		}

		if (availableRecurringFrequencies.indexOf(FrequencyCode.Quarterly) >= 0) {
			recurringFrequencyOptions.push({
				Label: 'Every 3 months',
				Value: `${FrequencyCode.Quarterly}`,
			});
		}

		if (availableRecurringFrequencies.indexOf(FrequencyCode.SemiYearly) >= 0) {
			recurringFrequencyOptions.push({
				Label: 'Every 6 months',
				Value: `${FrequencyCode.SemiYearly}`,
			});
		}

		if (availableRecurringFrequencies.indexOf(FrequencyCode.Yearly) >= 0) {
			recurringFrequencyOptions.push({
				Label: 'Every year',
				Value: `${FrequencyCode.Yearly}`,
			});
		}

		return recurringFrequencyOptions;
	}

	private renderRecurringEndTypeOptions() {
		return (
			<Fragment>
				<div className="row">
					<GivingFrequencySelectorWithLabel
						acceptanceTestTargetId={'pcgl-recurring-end-type'}
						labelForGivingFrequencySelection={'Ending after'}
						selectedFrequencyValue={`${this.props.vm.recurringEndType}`}
						availableFrequencies={[
							{
								Label: 'Never',
								Value: `${RecurringEndType.Never}`,
							},
							{
								Label: 'Date',
								Value: `${RecurringEndType.Date}`,
							},
							{
								Label: 'Occurrences',
								Value: `${RecurringEndType.Occurrences}`,
							},
						]}
						onChange={this.handleRecurringEndChange}
						isDisabled={this.isDisabledStateWhileGenerating()}
					/>
				</div>

				{this.props.vm.recurringEndType === RecurringEndType.Date && (
					<div className="row">
						<FormControlLabelled
							label="End Date"
							formControlProps={{
								name: ModelMetadata.PreconfiguredGivingLinkGenerationRequest.EndDate.propertyName,
								formControlType: FormControlType.DatePicker,
								placeholder: 'Choose a date',
								value: this.props.vm.recurringEndDate,
								onChangeHandler: this.onEndDateChange,
								options: {
									minDate: this.props.vm.recurringMinEndDate,
								},
								validationRules:
									ModelMetadata.PreconfiguredGivingLinkGenerationRequest.EndDate.validationRules,
								acceptanceTestTargetId: 'pcgl-recurring-end-date',
								disabled: this.isDisabledStateWhileGenerating(),
							}}
						/>
					</div>
				)}

				{this.props.vm.recurringEndType === RecurringEndType.Occurrences && (
					<div className="row">
						<FormControlLabelled
							label="Number of occurrences"
							formControlProps={{
								name: ModelMetadata.PreconfiguredGivingLinkGenerationRequest.RecurringEndOccurrences
									.propertyName,
								placeholder: '1 - 1,000',
								type: 'number',
								formControlType: FormControlType.Number,
								value: `${this.props.vm.recurringEndOccurrences}`,
								onChangeHandler: this.handleRecurringEndOccurrencesChange,
								validationRules:
									ModelMetadata.PreconfiguredGivingLinkGenerationRequest.RecurringEndOccurrences
										.validationRules,
								acceptanceTestTargetId: 'pcgl-recurring-end-occurrences',
								disabled: this.isDisabledStateWhileGenerating(),
							}}
						/>
					</div>
				)}
			</Fragment>
		);
	}

	private renderLanguageSelector() {
		const radioOptions = [];
		Object.keys(LanguageOptions).map((k) => {
			const option = LanguageOptions[k];
			radioOptions.push({
				Label: option.Name,
				Value: option.TwoLetterIsoCode,
			});
		});

		return (
			<Fragment>
				<div className={'row'} style={{ paddingTop: 13 }}>
					<SmoothHeightTransition>
						<FormControlLabelled
							label={'Language'}
							formControlProps={{
								name: ModelMetadata.PreconfiguredGivingLinkGenerationRequest.Language.propertyName,
								formControlType: FormControlType.Radio,
								value: `${this.props.vm.language.TwoLetterIsoCode}`,
								onChangeHandler: this.handleLanguageChange,
								Options: radioOptions,
								acceptanceTestTargetId: 'pcgl-language',
								radioItemClassName: styles.preconfiguredGivingRadioButton,
								disabled: this.isDisabledStateWhileGenerating(),
							}}
						/>
					</SmoothHeightTransition>
				</div>
			</Fragment>
		);
	}

	private renderNotes() {
		const { note, paymentLabel } = this.props.vm;
		return (
			<Fragment>
				<div className="row" style={{ paddingTop: 23 }}>
					<FormControlLabelled
						label={'Notes (Optional)'}
						formControlProps={{
							name: ModelMetadata.PreconfiguredGivingLinkGenerationRequest.Note.propertyName,
							formControlType: FormControlType.Textarea,
							value: note,
							onChangeHandler: this.handleNoteChange,
							acceptanceTestTargetId: 'pcgl-note-url',
							disabled: this.isDisabledStateWhileGenerating(),
						}}
						tooltipOptions={{
							className: styles.preconfiguredGivingFundTooltipContainer,
							message: `These notes are for internal purposes only. Your ${paymentLabel.PayerPluralLowerCase} will not be able to see any notes you have captured here.`,
						}}
					/>
				</div>
			</Fragment>
		);
	}

	private renderQrCodeLogoDisplayOptions() {
		return (
			<FormControlCheckbox
				value={this.props.vm.displayQrCodeLogo}
				onChangeHandler={this.handleDisplayQrCodeLogoChange}
				text={'Display logo'}
				acceptanceTestTargetId="qr-code-logo-display-toggle"
				disabled={this.isDisabledStateWhileGenerating()}
			/>
		);
	}

	private renderEmbeddedRedirectOptions() {
		return (
			<div className={classNames('row', styles.embeddedFormRedirect)}>
				<FormControlLabelled
					label={`${this.props.vm.paymentLabel.NounSentenceCase} Redirect Button`}
					tooltipOptions={{
						message: `After someone has ${this.props.vm.paymentLabel.VerbPastTenseLowerCase} to this fund, choose where you want to send them.`,
					}}
					formControlProps={{
						name: ModelMetadata.PreconfiguredGivingLinkGenerationRequest.GiftType.propertyName,
						formControlType: FormControlType.Radio,
						value: `${this.props.vm.embeddedRedirectType}`,
						onChangeHandler: this.handleChangeRedirectType,
						Options: [
							{
								Label: 'None',
								Value: `${EmbeddedRedirectType.None}`,
							},
							{
								Label: 'Manual redirect',
								Value: `${EmbeddedRedirectType.Manual}`,
							},
						],
						acceptanceTestTargetId: 'pcgl-gift-type',
						radioItemClassName: styles.preconfiguredGivingRadioButton,
						disabled: this.isDisabledStateWhileGenerating(),
					}}
				/>
				{this.renderEmbeddedRedirectType()}
			</div>
		);
	}

	private renderEmbeddedRedirectType() {
		switch (this.props.vm.embeddedRedirectType) {
			case EmbeddedRedirectType.Manual:
				return (
					<div className={styles.embeddedFormRedirectFields}>
						<div style={{ paddingTop: 13 }}>
							<FormControlLabelled
								label="Redirect button title"
								hideErrors={!this.props.vm.embeddedRedirectTextIsInvalid}
								formControlProps={{
									name: ModelMetadata.EmbeddedFormViewModelRequest.EmbeddedRedirectButtonText.propertyName,
									formControlType: FormControlType.Text,
									value: this.props.vm.EmbeddedRedirectButtonText,
									onChangeHandler: this.onEmbeddedTextChange,
									type: 'text',
									readonly: false,
									isInvalid: this.props.vm.embeddedRedirectTextIsInvalid,
									placeholder: 'Enter a title',
									validationRules:
										ModelMetadata.EmbeddedFormViewModelRequest.EmbeddedRedirectButtonText.validationRules,
									acceptanceTestTargetId: 'embedded-redirect-text',
									disabled: this.isDisabledStateWhileGenerating(),
								}}
							/>
						</div>
						<div style={{ paddingTop: 13 }}>
							<FormControlLabelled
								label="Redirect link"
								hideErrors={!this.props.vm.embeddedRedirectUrlIsInvalid}
								formControlProps={{
									type: 'text',
									name: ModelMetadata.EmbeddedFormViewModelRequest.EmbeddedRedirectButtonUrl.propertyName,
									formControlType: FormControlType.Text,
									value: this.props.vm.EmbeddedRedirectButtonUrl,
									onChangeHandler: this.onEmbeddedUrlChange,
									placeholder: 'Enter a url (e.g. https://www.example.com)',
									validationRules:
										ModelMetadata.EmbeddedFormViewModelRequest.EmbeddedRedirectButtonUrl.validationRules,
									isInvalid: this.props.vm.embeddedRedirectUrlIsInvalid,
									acceptanceTestTargetId: 'embedded-redirect-url',
									disabled: this.isDisabledStateWhileGenerating(),
								}}
							/>
						</div>
					</div>
				);
			default:
				return null;
		}
	}

	private removeSpacing = (content: string) => {
		return String(content).replace(/\t/g, '');
	}

	private renderEmbeddedFormOutputField() {
		const { formGenerationInfo, embeddedGivingScriptGenerationFlag } = this.props.vm;

		try {
			let form = null;

			if (formGenerationInfo) {
				form = embeddedGivingScriptGenerationFlag
					? getEmbeddedScript(formGenerationInfo)
					: this.removeSpacing(renderToStaticMarkup(
							<EmbeddedFormGenerator
								frequencies={this.getRecurringFrequencyOptions()}
								formInfo={formGenerationInfo}
							/>
					  ))
			}

			return (
				<div className={styles.embeddedFormResult}>
					<FormControlLabelled
						label={`Embedded Widget`}
						tooltipOptions={{
							message: `Embed a ${this.props.vm.paymentLabel.NounLowerCase} widget onto your website, where a ${this.props.vm.paymentLabel.PayerLowerCase} can start to fill out ${this.props.vm.paymentLabel.NounLowerCase} details.`,
						}}
						formControlProps={{
							name: 'GivingLinkResult',
							value: form,
							formControlType: FormControlType.Clipboard,
							placeholder: `Widget ${embeddedGivingScriptGenerationFlag ? 'script' : 'Html'} will be displayed once generated below`,
							cssClassNames: styles.embeddedFormFieldInput,
						}}
					/>
					{DEBUG && form && !embeddedGivingScriptGenerationFlag && (
						<div className={styles.embeddedFormPreview} dangerouslySetInnerHTML={{ __html: form }} />
					)}
				</div>
			);
		} catch (error) {
			return <>Something went wrong</>;
		}
	}

	private handleListingChange = (listingHandle: string) => {
		this.props.vm.changeSelectListing(listingHandle);
	};

	private handleSelectedFundChange = (selectedFund: React.FormEvent<HTMLSelectElement>) => {
		this.props.vm.changeSelectedFund(selectedFund.currentTarget.value);
	};

	private handleFundVisibilityChange = (fundVisibility: string) => {
		this.props.vm.changeFundVisibility(parseInt(fundVisibility));
	};

	private onAmountChange = (amount: number) => {
		this.props.vm.changeAmount(amount);
	};

	private onEmbeddedTextChange = (textEvent: React.FormEvent<HTMLInputElement>) => {
		this.props.vm.changeEmbeddedRedirectText(textEvent.currentTarget.value);
	};

	private onEmbeddedUrlChange = (urlEvent: React.FormEvent<HTMLInputElement>) => {
		this.props.vm.changeEmbeddedRedirectUrl(urlEvent.currentTarget.value);
	};

	private handleIsFixedAmountChange = (isFixedAmount: boolean) => {
		this.props.vm.changeIsFixedAmount(isFixedAmount);
	};

	private handleGiftTypeChange = (giftType: string) => {
		this.props.vm.changeGiftType(parseInt(giftType));
	};

	private handleRecurringVisibilityChange = (recurringVisibility: boolean) => {
		this.props.vm.changeRecurringVisibility(recurringVisibility);
	};

	private handleRecurringFrequencyChange = (frequencyCode: string) => {
		this.props.vm.changeRecurringFrequency(parseInt(frequencyCode));
	};

	private onStartDateChange = (startDate: Date) => {
		this.props.vm.changeStartDate(startDate);
	};

	private disableDays = (date: Date) => {
		if (this.props.vm.frequencyCode === FrequencyCode.FirstAndFifteenth) {
			return date.getDate() !== 1 && date.getDate() !== 15;
		}
		return false;
	};

	private handleRecurringEndChange = (recurringEndType: string) => {
		this.props.vm.changeRecurringEndType(parseInt(recurringEndType));
	};

	private onEndDateChange = (endDate: Date) => {
		this.props.vm.changeEndDate(endDate);
	};

	private handleRecurringEndOccurrencesChange = (recurringEndOccurrenceEvent: React.FormEvent<HTMLInputElement>) => {
		this.props.vm.changeRecurringEndOccurrences(parseInt(recurringEndOccurrenceEvent.currentTarget.value));
	};

	private handleLanguageChange = (selectedLanguageCode: string) => {
		this.props.vm.changeLanguage(selectedLanguageCode);
	};

	private handleNoteChange = (noteEvent: React.FormEvent<HTMLTextAreaElement>) => {
		this.props.vm.changeNote(noteEvent.currentTarget.value);
	};

	private handleDisplayQrCodeLogoChange = (displayQrCodeLogo: boolean) => {
		this.props.vm.changeDisplayQrCodeLogo(displayQrCodeLogo);
	};

	private handleSetLinkTypeToPCGL = () => {
		this.props.vm.setLinkType(LinkType.PCGLAndQRCode);
	};

	private handleSetLinkTypeToEmbedded = () => {
		this.props.vm.setLinkType(LinkType.EmbeddedForm);
	};

	private handleChangeRedirectType = (type: string) => {
		this.props.vm.changeRedirectType(parseInt(type));
	};

	private isDisabledStateWhileGenerating = () => this.props.vm.isGeneratingQrCode;
}
