import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { Formatter } from '../../../helpers/formatter';
import { classNames } from '../../../../Shared/utils/classnames';
import * as styles from './campaign-progress.less';
import { SvgWrapper } from '../../../../Shared/components/svg-wrapper';


interface CampaignProgressProps {
	goalAmount: number;
	collectedAmount: number;
	animationInSeconds?: number;
	closed?: boolean;
	className?: string;
	animationDelay?: number;
}

const framesPerSecond = 60;
const defaultAnimationInSeconds = 3;

@observer
export class CampaignProgress extends React.Component<CampaignProgressProps> {

	@observable
	private progress: number = 0;

	private animationInSeconds;
	private progressInFrames: number;
	private totalFrames: number;
	private endpoint: number;
	private acceleration: number;
	private currentRequestId: number;
	private animationTimeoutId: number;
	private isAnimationDelayComplete: boolean = true;


	constructor(props) {
		super(props);
		const { collectedAmount, goalAmount } = this.props;

		this.configurateAnimation();
		this.endpoint = Math.max(goalAmount, collectedAmount);
	}


	componentDidMount() {
		this.animationTimeoutId = window.setTimeout(() => {
			this.isAnimationDelayComplete = false;
			this.currentRequestId = window.requestAnimationFrame(this.updateProgress);
		}, this.props.animationDelay || 0);
	}

	componentDidUpdate(prevProps: CampaignProgressProps) {
		const { collectedAmount, goalAmount, animationInSeconds } = this.props;
		if (prevProps.animationInSeconds !== animationInSeconds || prevProps.collectedAmount !== collectedAmount) {
			window.cancelAnimationFrame(this.currentRequestId);

			this.endpoint = Math.max(goalAmount, collectedAmount);
			this.configurateAnimation();

			if (!this.isAnimationDelayComplete) {
				this.currentRequestId = window.requestAnimationFrame(this.updateProgress);
			}
		}
	}

	componentWillUnmount() {
		window.cancelAnimationFrame(this.currentRequestId);
		window.clearTimeout(this.animationTimeoutId);
	}

	@action.bound
	updateProgress() {
		const { collectedAmount } = this.props;
		const remainingProgressInFrames = this.totalFrames - this.progressInFrames;
		this.progress = Math.max(collectedAmount - this.acceleration * remainingProgressInFrames * remainingProgressInFrames / 2, 0);
		this.progressInFrames++;

		if (this.progress < collectedAmount) {
			this.currentRequestId = window.requestAnimationFrame(this.updateProgress);
		}
	}

	render() {
		const { collectedAmount, goalAmount, closed, className } = this.props;
		const showCollectedPercentage = !closed && (collectedAmount < goalAmount) && (collectedAmount !== 0);
		const showGoalReached = (collectedAmount >= goalAmount) && (this.progress >= goalAmount);
		const showEndPoint = (collectedAmount < goalAmount);

		return (
			<div className={className} data-chromatic="ignore">
				<div className={styles.axis}>
					<span className={classNames(styles.startPoint, collectedAmount === 0 ? '' : (closed ? styles.startPointClosed : styles.startPointActive))}></span>
					<div className={classNames(styles.progress, closed ? styles.progressClosed : styles.progressActive)} style={{ width: this.getProgressPercentage(this.progress) }}>
						{showCollectedPercentage && <span className={styles.collectedPercentage}>
							{Formatter.formatNumberForDisplay(this.progress * 100 / goalAmount, 0)}%
						</span>}
					</div>
					{showGoalReached
						&& <span className={classNames(styles.goalReachedBar, closed && styles.goalReachedBarClosed)} style={{ width: `calc(100% * ${goalAmount / collectedAmount}` }}>
							<SvgWrapper svg="ind-tick" />
							<span className={styles.goalReachedBarText}>Goal reached</span>
						</span>}
					{showEndPoint && <span className={styles.endPoint}></span>}
				</div>
			</div>
		);
	}

	private configurateAnimation() {
		const { animationInSeconds, collectedAmount } = this.props;
		if (animationInSeconds === 0 || collectedAmount === 0) {
			// No animation
			this.totalFrames = 0;
			this.acceleration = 0;
		} else {
			this.animationInSeconds = animationInSeconds || defaultAnimationInSeconds;
			this.totalFrames = this.animationInSeconds * framesPerSecond;
			this.acceleration = collectedAmount * 2 / (this.totalFrames * this.totalFrames);
		}
		this.progressInFrames = 0;
	}

	private getProgressPercentage(amount: number): string {
		return 100 * (amount / this.endpoint) + '%';
	}
}
