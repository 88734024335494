import * as React from 'react';
import { ComponentLoader } from '../../Shared/helpers/component-loader';
import { ElvantoIntegration } from '../integrations/pages/configure-elvanto';
import { CcbIntegration } from '../integrations/pages/configure-ccb';
import { ThinkMinistryIntegration } from '../integrations/pages/configure-thinkministry';
import { FellowshipOneIntegration } from '../integrations/pages/configure-fellowship-one';
import { PlanningCenterIntegration } from '../integrations/pages/configure-planningcenter';
import { ShelbyArenaIntegration } from '../integrations/pages/configure-shelbyarena';
import { ImportFundsPanel, ImportFundsHeader, AddNewFundLink, PaginatedImportFundsPanel } from '../funds/components/import-funds-panel';
import { IMultiSelectProps, MultiSelect, MultiSelectStore } from '../components/multi-select';
import {
	IMultiSelectProps as IMultiSelectProps2,
	MultiSelect as MultiSelect2,
} from '../components/multi-select/multi-select';
import { MultiSelectStore as MultiSelectStore2 } from '../components/multi-select/multi-select-store';
import {
	IMultiSelectedItemsProps,
	MultiSelectedItemsOld,
} from '../components/multi-selected-items-old/multi-selected-items-old';
import { IAutocompleteProps, Autocomplete, AutocompleteStore } from '../components/autocomplete';
import { EmailRecipientList, EmailRecipientListStore } from '../components/email-recipient-list';
import { IntegrationActions } from '../integrations/components/integration-actions';
import { EnableIntegrationButton } from '../integrations/components/enable-integration-button';
import { IntegrationServiceClient } from '../integrations/components/integration-service-client';
import {
	IntegrationFundAutocomplete,
	IIntegrationFundAutocompleteProps,
	IntegrationFundAutocompleteStore,
} from '../integrations/components/integration-fund-autocomplete';
import { IHintProps, Hint } from '../components/hint';
import { IPopupLinkProps, PopupLink, PopupCloseLink } from '../components/popup-helper';
import StoreLoader from './store-loader';
import MainConsolidator from '../community-member/views/main-consolidator';
import PreviewStatement from '../community-member/views/preview-statement';
import { EditableLabel, EditableLabelProps } from '../components/editable-label';
import { VirtualTerminalMain } from '../virtual-terminal/pages/single/single-entry-main';
import { BatchEntryMain } from '../virtual-terminal/pages/batch-entry/batch-entry-main';
import { EntryDashboardMain } from '../virtual-terminal/pages/entry-dashboard/entry-dashboard-main';
import { BatchListMain } from '../virtual-terminal/pages/batch-list/batch-list-main';
import { AllocationView } from '../check-deposit/allocation/allocation-view';
import { RegisteredScannersView } from '../check-deposit/scanners/registered-scanners-view';
import { BatchListView } from '../check-deposit/batch/batch-list-view';
import { FundListView } from '../funds/page-fund-list';
import { ConfettiAnimationWithPortal, IConfettiProps } from '../../Shared/components/confetti/confetti';
import { ManageFundsView } from '../funds/page-manage-funds';
import { SplitPaymentInfoTooltip } from '../transactions/split-checks/split-payment-info-tooltip';
import { TransactionImportFundMatching } from '../transaction-import/transaction-import-fund-matching';
import { TransactionImportUpload } from '../transaction-import/import-file-upload-page';
import { TransactionImportSummary } from '../transaction-import/transaction-import-summary';
import { TransactionImportCreate } from '../transaction-import/transaction-import-create';
import { ScheduleImportCreate } from '../schedule-import/pages/create-import/schedule-import-create';
import { ScheduleImportFileUpload } from '../schedule-import/pages/file-upload/schedule-import-file-upload';
import { ScheduleImportFundMatching } from '../schedule-import/pages/fund-matching/schedule-import-fund-matching';
import { ScheduleImportProgress } from '../schedule-import/pages/import-progress/schedule-import-progress';
import { ScheduleImportReview } from '../schedule-import/pages/review-import/schedule-import-review';
import { ScheduleImportSetupAlert } from '../schedule-import/pages/setup-alert/schedule-import-setup-alert';
import { ScheduleMigrationEdit } from '../schedule-import/pages/edit-migration/schedule-migration-edit';
import { MigrationTips } from '../schedule-import/migration-tips/migration-tips';
import { ServiceTimeMain } from '../settings/pages/service-time/service-time-main';
import { QboConfigurationMain } from '../qbo-integration/pages/configuration/qbo-configuration-main';
import { BrandingSettingsMain } from '../branding-settings/branding-settings-main';
import { ResourceCenterDashboard } from '../resource-center/resource-center-dashboard';
import { ExportButton } from '../transactions/export-button';
import { NewScreenSwitch } from '../transactions/new-screen-switch';
import { MigrationSummaryDashboard } from '../schedule-import/pages/dashboard/index';
import { SchedulesDetails } from '../schedule-import/pages/schedules-details';
import { GivingStatements } from '../giving-statements/app';
import { CreateCampaignMain } from '../campaigns/pages/create-campaign/create-campaign-main';
import { CampaignsLandingApp } from '../campaigns/pages/campaigns-landing/campaigns-landing-app';
import { CampaignsOutageMain } from '../campaigns/pages/campaigns-outage/campaigns-outage-main';
import { CampaignDetailsMain } from '../campaigns/pages/campaign-details/campaign-details-main';
import { PledgeEntryMain } from '../campaigns/pages/pledge-entry/pledge-entry-main';
import { Shell } from '../shell';
import { NavViewModel } from '../loggedinweb-generated';
import { PreconfiguredGivingLinks } from '../preconfigured-giving-links/index';
import { KeywordsList, IKeywordsListProps } from '../resource-center/components/keywords-list';
import { PaymentEntryRecurringEdit } from '../virtual-terminal/components/payment-entry/payment-entry-recurring-edit/payment-entry-recurring-edit';
import { Pinpoint } from '../components/pinpoint';

const loader = new ComponentLoader();

loader.registerComponent('ElvantoIntegration', ElvantoIntegration, (storeName) => {
	return { controller: StoreLoader.getStore(storeName) };
});

loader.registerComponent('CcbIntegration', CcbIntegration, (storeName) => {
	return { controller: StoreLoader.getStore(storeName) };
});

loader.registerComponent('FellowshipOneIntegration', FellowshipOneIntegration, (storeName) => {
	return { controller: StoreLoader.getStore(storeName) };
});

loader.registerComponent('ThinkMinistryIntegration', ThinkMinistryIntegration, (storeName) => {
	return { controller: StoreLoader.getStore(storeName) };
});

loader.registerComponent('PlanningCenterIntegration', PlanningCenterIntegration, (storeName) => {
	return { controller: StoreLoader.getStore(storeName) };
});

loader.registerComponent('ShelbyArenaIntegration', ShelbyArenaIntegration, (storeName) => {
	return { controller: StoreLoader.getStore(storeName) };
});

loader.registerComponent('IntegrationActions', IntegrationActions, (storeName) => {
	return { store: StoreLoader.getStore(storeName) };
});

loader.registerComponent('EnableIntegrationButton', EnableIntegrationButton, (viewModel) => {
	const serviceClient = new IntegrationServiceClient(
		viewModel.Metadata.IntegrationSystem.DisplayName,
		viewModel.IsThirdParty,
		viewModel.HasFunds
	);
	serviceClient.actionUrls = viewModel.ActionUrls;
	return { serviceClient: serviceClient };
});

loader.registerComponent('ImportFundsPanel', ImportFundsPanel, (storeName) => {
	return { importFundsStore: StoreLoader.getStore(storeName) };
});

loader.registerComponent('PaginatedImportFundsPanel', PaginatedImportFundsPanel, (storeName) => {
	return { importFundsStore: StoreLoader.getStore(storeName) };
});

loader.registerComponent('ImportFundsHeader', ImportFundsHeader, (storeName) => {
	return { importFundsStore: StoreLoader.getStore(storeName) };
});

loader.registerComponent('AddNewFundLink', AddNewFundLink, (storeName) => {
	return { importFundsStore: StoreLoader.getStore(storeName) };
});

loader.registerComponent('MultiSelect', MultiSelect, (data): IMultiSelectProps => {
	return {
		multiSelectStore: new MultiSelectStore(data.viewModel),
		placeholderText: data.placeholderText,
		validationRules: data.validationRules,
		fieldName: data.fieldName,
	};
});

loader.registerComponent('MultiSelect2', MultiSelect2, (data): IMultiSelectProps2 => {
	const multiSelectStore = new MultiSelectStore2(data.viewModel, data.displayName);

	if (data.useMultiSelectedItemsStore) {
		StoreLoader.getStore('MultiSelectedItemsStore').addStore(multiSelectStore);
	}

	return {
		multiSelectStore,
		validationRules: data.validationRules,
		fieldName: data.fieldName,
		displayName: data.displayName || data.fieldName,
		acceptanceTestTargetId: data.acceptanceTestTargetId,
	};
});

loader.registerComponent('MultiSelectedItems', MultiSelectedItemsOld, (data): IMultiSelectedItemsProps => {
	return {
		multiSelectPageStore: StoreLoader.getStore('MultiSelectedItemsStore'),
	};
});

loader.registerComponent('Autocomplete', Autocomplete, (data): IAutocompleteProps => {
	return {
		autocompleteStore: new AutocompleteStore(data.viewModel),
		emptyText: data.emptyText,
		validationRules: data.validationRules,
		floatingLabelText: data.floatingLabelText,
		fieldName: data.fieldName,
	};
});

loader.registerComponent(
	'IntegrationFundAutocomplete',
	IntegrationFundAutocomplete,
	(data): IIntegrationFundAutocompleteProps => {
		return {
			autocompleteStore: new IntegrationFundAutocompleteStore(data.integrationFunds, data.selectedValue),
			emptyText: data.emptyText,
			validationRules: data.validationRules,
			floatingLabelText: data.floatingLabelText,
			fieldName: data.fieldName,
			validateOnInit: data.validateOnInit,
			disabled: data.disabled
		};
	}
);

loader.registerComponent('EmailRecipientList', EmailRecipientList, (data) => {
	return { emailRecipientListStore: new EmailRecipientListStore(data) };
});

loader.registerComponent('CommunityMembersConsolidator', MainConsolidator, (storeName) => {
	return { vm: StoreLoader.getStore(storeName) };
});

loader.registerComponent('PreviewStatement', PreviewStatement, (storeName) => {
	return { vm: StoreLoader.getStore(storeName) };
});

loader.registerComponent('Hint', Hint, (data) => {
	return { text: data.text, additionalClasses: data.additionalClasses } as IHintProps;
});

loader.registerComponent('PopupLink', PopupLink, (data) => {
	return { popup: data.popup, className: data.className } as IPopupLinkProps;
});

loader.registerComponent('PopupCloseLink', PopupCloseLink, (data) => {
	return { popup: data.popup } as IPopupLinkProps;
});

loader.registerComponent('EditableLabel', EditableLabel, (data) => {
	return { value: data.value } as EditableLabelProps;
});

loader.registerComponent('BatchEntryMain', BatchEntryMain, (storeName) => {
	return { vm: StoreLoader.getStore(storeName) };
});

loader.registerComponent('EntryDashboardMain', EntryDashboardMain, (storeName) => {
	return { vm: StoreLoader.getStore(storeName) };
});

loader.registerComponent('VirtualTerminalMain', VirtualTerminalMain, (storeName) => {
	return { vm: StoreLoader.getStore(storeName) };
});

loader.registerComponent('BatchListMain', BatchListMain, (storeName) => {
	return { vm: StoreLoader.getStore(storeName) };
});

loader.registerComponent('BatchListMain', BatchListMain, (storeName) => {
	return { vm: StoreLoader.getStore(storeName) };
});

loader.registerComponent('AllocationView', AllocationView, (storeName) => {
	return { store: StoreLoader.getStore(storeName) };
});

loader.registerComponent('CheckDepositBatchListView', BatchListView, (storeName) => {
	return { store: StoreLoader.getStore(storeName) };
});

loader.registerComponent('RegisteredScannersView', RegisteredScannersView, (storeName) => {
	return { store: StoreLoader.getStore(storeName) };
});

loader.registerComponent('ManageFundsView', ManageFundsView, (data) => {
	return { model: data };
});

loader.registerComponent('FundListView', FundListView, (data) => {
	return { model: data };
});

loader.registerComponent('SplitPaymentInfoTooltip', SplitPaymentInfoTooltip, ({ merchantId, splitPaymentId, isCheck }) => {
	return { merchantId, splitPaymentId, isCheck };
});

loader.registerComponent('TransactionImportFundMatching', TransactionImportFundMatching, (storeName) => {
	return { vm: StoreLoader.getStore(storeName) };
});

loader.registerComponent('TransactionImportUpload', TransactionImportUpload, (storeName) => {
	return { store: StoreLoader.getStore(storeName) };
});

loader.registerComponent('TransactionImportSummary', TransactionImportSummary, (storeName) => {
	return { vm: StoreLoader.getStore(storeName) };
});

loader.registerComponent('TransactionImportCreate', TransactionImportCreate, (storeName) => {
	return { vm: StoreLoader.getStore(storeName) };
});

loader.registerComponent('ScheduleImportCreate', ScheduleImportCreate, (storeName) => {
	return { vm: StoreLoader.getStore(storeName) };
});

loader.registerComponent('ScheduleImportFileUpload', ScheduleImportFileUpload, (storeName) => {
	return { vm: StoreLoader.getStore(storeName) };
});

loader.registerComponent('ScheduleImportFundMatching', ScheduleImportFundMatching, (storeName) => {
	return { vm: StoreLoader.getStore(storeName) };
});

loader.registerComponent('ScheduleImportProgress', ScheduleImportProgress, (storeName) => {
	return { vm: StoreLoader.getStore(storeName) };
});

loader.registerComponent('ScheduleImportReview', ScheduleImportReview, (storeName) => {
	return { vm: StoreLoader.getStore(storeName) };
});

loader.registerComponent('ScheduleImportSetupAlert', ScheduleImportSetupAlert, (storeName) => {
	return { vm: StoreLoader.getStore(storeName) };
});

loader.registerComponent('ScheduleMigrationEdit', ScheduleMigrationEdit, (storeName) => {
	return { vm: StoreLoader.getStore(storeName) };
});

loader.registerComponent('MigrationTips', MigrationTips, (data) => {
	return { vm: data };
});

loader.registerComponent('ServiceTimeMain', ServiceTimeMain, (storeName) => {
	return { vm: StoreLoader.getStore(storeName) };
});

loader.registerComponent('QboConfigurationMain', QboConfigurationMain, (storeName) => {
	return { vm: StoreLoader.getStore(storeName) };
});

loader.registerComponent('BrandingSettingsMain', BrandingSettingsMain, (storeName) => {
	const context = StoreLoader.getStore(storeName);
	return {
		vm: context.mainViewModel,
		raiseAction: (action) => context.userActionChannel.put(action),
	};
});

loader.registerComponent('ResourceCenterDashboard', ResourceCenterDashboard, (storeName) => {
	return { vm: StoreLoader.getStore(storeName) };
});

loader.registerComponent('ExportButton', ExportButton, (data) => {
	return { vm: data };
});

loader.registerComponent('NewScreenSwitch', NewScreenSwitch, (data) => {
	return { vm: data };
});

loader.registerComponent('MigrationSummaryDashboard', MigrationSummaryDashboard, (storeName) => {
	return { vm: StoreLoader.getStore(storeName) };
});

loader.registerComponent('SchedulesDetails', SchedulesDetails, (storeName) => {
	return { vm: StoreLoader.getStore(storeName) };
});

loader.registerComponent('GivingStatements', GivingStatements, (storeName) => {
	return { vm: StoreLoader.getStore(storeName) };
});

loader.registerComponent('CreateCampaignMain', CreateCampaignMain, (storeName) => {
	return { vm: StoreLoader.getStore(storeName) };
});

loader.registerComponent('CampaignsLandingApp', CampaignsLandingApp, (storeName) => {
	return { store: StoreLoader.getStore(storeName) };
});

loader.registerComponent('CampaignsOutageMain', CampaignsOutageMain, (storeName) => {
	return { vm: StoreLoader.getStore(storeName) };
});

loader.registerComponent('CampaignDetailsMain', CampaignDetailsMain, (storeName) => {
	return { store: StoreLoader.getStore(storeName) };
});

loader.registerComponent('PledgeEntryMain', PledgeEntryMain, (storeName) => {
	return { vm: StoreLoader.getStore(storeName) };
});


loader.registerComponent('Shell', Shell, (vm: NavViewModel) => {
	return { vm };
});

loader.registerComponent('PreconfiguredGivingLinks', PreconfiguredGivingLinks, (storeName) => {
	return { vm: StoreLoader.getStore(storeName) };
});

loader.registerComponent('KeywordsList', KeywordsList, (vm): IKeywordsListProps => {
	return { vm };
});

loader.registerComponent('ConfettiAnimationWithPortal', ConfettiAnimationWithPortal, (vm): IConfettiProps & any => {
	return { ...vm };
});

loader.registerComponent('PaymentEntryRecurringEdit', PaymentEntryRecurringEdit, (vm) => {
	return { vm };
});

loader.registerComponent('Pinpoint', Pinpoint, pinpointViewModel => pinpointViewModel);

export const init = () => loader.init();
