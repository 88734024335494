import * as React from 'react';
import {
	EmbeddedFormViewModelResult,
	FrequencyCode,
	PCGLQueryKeys,
} from '../../preconfigured-giving-links/preconfigured-giving-links-generated';
import { IFormControlRadioOptionProps } from '../../components/form-controls/form-control-radio';
import tinycolor from 'tinycolor2';

export const getEmbeddedScript = (info: EmbeddedFormViewModelResult) => `
<script>!function(){window.pushpayEmbeddedConfig={handle:"${info.Handle}",wgc:"${info.WebGivingSignedConfig}"};var e=document.createElement("script");e.type="text/javascript",e.async=!0,e.src="${info.ScriptSourceUrl}",document.getElementsByTagName("head")[0].appendChild(e)}()</script>
`;

export interface IEmbeddedFormGeneratorProps {
	formInfo: EmbeddedFormViewModelResult;
	frequencies: IFormControlRadioOptionProps[];
}

export class EmbeddedFormGenerator extends React.Component<IEmbeddedFormGeneratorProps> {
	render() {
		const {
			formInfo,
			formInfo: { PaymentLabel },
		} = this.props;
		const {
			BeaconUrl,
			CryptoLink,
			StockLink,
			WebGivingSignedConfig,
			SourceQueryName,
			EmbeddedSourceKey,
			SignedConfigQueryName,
		} = formInfo;

		return (
			<div id="pp-embeddedContainer">
				{this.generateStyle()}
				<form
					id="pp-embeddedForm"
					action={formInfo.WebGivingUrl}
					method="get"
					style={{
						minWidth: '300px',
						maxWidth: '400px',
						borderRadius: '5px',
						textAlign: 'left',
						fontFamily: 'sans-serif',
						position: 'relative',
						display: 'flex',
						flexDirection: 'column',
						fontSize: '16px',
						backgroundColor: '#fff',
						margin: '30px auto',
						padding: ' 24px !important',
						boxShadow: '0 0px 10px 0px rgba(0, 0, 0, 0.1)',
					}}
				>
					<input type="hidden" name={SourceQueryName} value={EmbeddedSourceKey} />
					{WebGivingSignedConfig && (
						<input type="hidden" name={SignedConfigQueryName} value={WebGivingSignedConfig} />
					)}
					<h4
						style={{
							fontWeight: 600,
							lineHeight: '24px',
							margin: '0',
							color: 'black',
							paddingBottom: '24px',
						}}
					>
						{PaymentLabel.VerbSentenceCase} now
					</h4>
					{this.generateErrorsField()}
					{this.generateAmountField()}
					{this.generateTabs()}
					{this.generateOneTimeFields()}
					{this.generateRecurringFields()}
					{this.generateFundsField()}
					<input type="submit" id="pp-submitButton" value="Next" />
					{(CryptoLink || StockLink) && this.generateEngivenLinks()}
					{this.generatePoweredBy()}
					{BeaconUrl && <img width="1" height="1" src={BeaconUrl} />}
				</form>
				{this.generateScript()}
			</div>
		);
	}

	private generateErrorsField = () => (
		<div id="pp-formErrors" className="pp-hidden">
			---
		</div>
	);

	private generateAmountField = () => {
		const {
			formInfo: { MaxPaymentAmount, MinPaymentAmount, PaymentLabel },
		} = this.props;
		return (
			<div id="pp-amountBox">
				<label htmlFor="pp-amount">
					I'd like to {PaymentLabel.VerbLowerCase} <i className="pp-required">*</i>
				</label>
				<span className="pp-amountPrefix">$</span>
				<input
					required
					id="pp-amount"
					type="number"
					name={PCGLQueryKeys.Amount}
					placeholder="0.00"
					step="0.01"
					max={MaxPaymentAmount}
					min={MinPaymentAmount}
				/>
			</div>
		);
	};

	private generateTabs = () => {
		const { PrefersRecurring } = this.props.formInfo;

		return (
			<div id="pp-tabContainer" style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '24px' }}>
				<button type="button" id="pp-tabOneTime" className={PrefersRecurring ? '' : 'pp-active'}>
					One time
				</button>
				<button type="button" className={PrefersRecurring ? 'pp-active' : ''} id="pp-tabRecurring">
					Recurring
				</button>
			</div>
		);
	};

	private generateOneTimeFields = () => (
		<div id="pp-oneTime">
			<input
				type="hidden"
				disabled={this.props.formInfo.PrefersRecurring}
				name={PCGLQueryKeys.Recurrence}
				id="pp-giveOnce"
				value="one"
			/>
		</div>
	);

	private generateRecurringFields = () => (
		<div id="pp-recurring" className={this.props.formInfo.PrefersRecurring ? '' : 'pp-hidden'}>
			{this.generateFrequencyField()}
			{this.generateStartDateField()}
			{this.props.formInfo.PayerSpecifyRecurringEndDate && this.generateEndTypesField()}
		</div>
	);

	private generateFrequencyField = () => {
		const {
			frequencies,
			formInfo: { PrefersRecurring },
		} = this.props;
		return (
			<div>
				<label htmlFor="pp-recurrence">
					Frequency <i className="pp-required">*</i>
				</label>
				<select id="pp-recurrence" required name={PCGLQueryKeys.Recurrence} disabled={!PrefersRecurring}>
					{frequencies.map((frequency, index) => (
						<option key={index} value={FrequencyCode[parseInt(frequency.Value)]}>
							{frequency.Label}
						</option>
					))}
				</select>
			</div>
		);
	};

	private generateStartDateField = () => {
		const {
			formInfo: { PrefersRecurring },
		} = this.props;

		return (
			<div>
				<label htmlFor="pp-startDate">
					Starting <i className="pp-required">*</i>
				</label>
				<input
					type="date"
					id="pp-startDate"
					name={PCGLQueryKeys.RecurringStartDate}
					disabled={!PrefersRecurring}
					required
				/>
			</div>
		);
	};

	private generateEndTypesField = () => {
		const {
			formInfo: { PrefersRecurring, PaymentLabel },
		} = this.props;

		return (
			<div>
				<label htmlFor="pp-endTypes">
					Ending <i className="pp-required">*</i>
				</label>
				<select id="pp-endTypes" required disabled={!PrefersRecurring} name={PCGLQueryKeys.RecurringEndType}>
					<option value="0">When I cancel it</option>
					<option value="1">On a specific date</option>
					<option value="2">After number of {PaymentLabel.NounPluralLowerCase}</option>
				</select>
				<div id="pp-endDateContainer" className="pp-hidden">
					<label htmlFor="pp-endDate">
						End date <i className="pp-required">*</i>
					</label>
					<input type="date" disabled required id="pp-endDate" name={PCGLQueryKeys.RecurringEndDate} />
				</div>
				<div id="pp-occurenceContainer" className="pp-hidden">
					<label htmlFor="pp-occurence">
						Number of {PaymentLabel.ActionPluralLowerCase} <i className="pp-required">*</i>
					</label>
					<input
						max="1000"
						min="1"
						required
						placeholder="e.g. 1-1000"
						type="number"
						spellCheck={false}
						id="pp-occurence"
						disabled
						name={PCGLQueryKeys.RecurringEndOccurence}
					/>
				</div>
			</div>
		);
	};

	private generateFundsField = () => {
		var defaultFundKey = this.props.formInfo.DefaultFundKey;
		var merchantFunds = this.props.formInfo.MerchantFundList;

		return (
			<div>
				<label htmlFor="pp-fundInput">
					Towards this fund <i className="pp-required">*</i>
				</label>
				<select value={defaultFundKey ? defaultFundKey : ''} id="pp-fundInput" name="fnd" required>
					{!defaultFundKey && (
						<option value="" disabled selected>
							-- Choose Fund --
						</option>
					)}
					{merchantFunds.map((fund, index) => (
						<option key={index} value={fund.FundKey}>
							{fund.FundName}
						</option>
					))}
				</select>
			</div>
		);
	};

	private generateEngivenLinks = () => {
		const { CryptoLink, StockLink } = this.props.formInfo;

		return (
			<div>
				<div id="pp-separator">
					<hr />
					<span>Or</span>
					<hr />
				</div>
				<div id="pp-stockAndCrypto">
					<div id="pp-engivenLinks">
						{CryptoLink && (
							<a href={CryptoLink} target="_blank" rel="noopener noreferrer">
								Cryptocurrency
							</a>
						)}
						{StockLink && (
							<a href={StockLink} target="_blank" rel="noopener noreferrer">
								Stocks
							</a>
						)}
					</div>
					<div id="pp-engivenInfo">
						<p>
							We have partnered with Engiven to make it easy to give with Cryptocurrency and Stocks. To
							make your gift, click a button above to be redirected to Engiven's website, enter your gift
							amount and fill out the form.
						</p>
						<small>
							All gifts will be converted to USD. Engiven is a trusted partner of Pushpay.
							<a
								href="https://help.pushpay.com/s/knowledge/How-to-Give-using-Cryptocurrency"
								target="_blank"
								rel="noopener noreferrer"
							>
								Click here
							</a>
							to learn more about giving in Cryptocurrency.
							<a
								href="https://help.pushpay.com/s/knowledge/How-to-Give-Using-Stock"
								target="_blank"
								rel="noopener noreferrer"
							>
								Click here
							</a>
							to learn more about giving Stocks.
							<a
								href="https://www.engiven.com/terms-of-service"
								target="_blank"
								rel="noopener noreferrer"
							>
								Engiven terms and conditions apply
							</a>
						</small>
					</div>
				</div>
			</div>
		);
	};

	private generatePoweredBy = () => (
		<div
			id="pp-poweredBy"
			style={{
				color: '#91949C',
				fontSize: '12px',
				display: 'flex',
				height: '16px',
				lineHeight: '16px',
				alignItems: 'center',
				justifyContent: 'center',
				paddingTop: '24px',
			}}
		>
			Powered by
			<img
				height="20"
				width="71"
				style={{ height: '20px', width: '71px', marginLeft: '5px', opacity: '0.6' }}
				src={this.props.formInfo.PushpayIconUrl}
				alt="powered by Pushpay"
			/>
		</div>
	);

	private generateScript = () => {
		const html = `
		'use strict';
		document.addEventListener('readystatechange', function () {
			if (document.readyState === 'complete') {
				/* --- DOM ELEMENTS --- */
				var ppForm = document.getElementById('pp-embeddedForm');
				var ppAmount = document.getElementById('pp-amount');
				var ppStartDate = document.getElementById('pp-startDate');
				var ppEndTypes = document.getElementById('pp-endTypes');

				var ppEndDateContainer = document.getElementById('pp-endDateContainer');
				var ppEndDate = document.getElementById('pp-endDate');

				var ppOccurenceContainer = document.getElementById('pp-occurenceContainer');
				var ppOccurence = document.getElementById('pp-occurence');
				var ppRecurrence = document.getElementById('pp-recurrence');

				var ppGiveOnce = document.getElementById('pp-giveOnce');
				var ppTabOneTimeBtn = document.getElementById('pp-tabOneTime');
				var ppTabRecurringBtn = document.getElementById('pp-tabRecurring');
				var ppRecurringContainer = document.getElementById('pp-recurring');

				var ppFormErrors = document.getElementById('pp-formErrors');
				var ppFund = document.getElementById('pp-fundInput');

				var payerCanSpecifyEndType = ppEndTypes;

				/* --- INITIALISERS  --- */

				ppSetStartDate(ppStartDate);

				/* --- FUNCTIONS --- */

				function ppGetDateString(date) {
					var dt = new Date(date);
					dt.setHours(0, 0, 0, 0);
					var month = dt.getMonth() + 1;
					var day = dt.getDate();
					var year = dt.getFullYear();
					if (month < 10) month = '0' + month.toString();
					if (day < 10) day = '0' + day.toString();
					return year + '-' + month + '-' + day;
				}

				function ppParseDateInput(date) {
					var parsed = ppStartDate.value.split('-');
					var dt = new Date();
					dt.setHours(0, 0, 0, 0);
					dt.setDate(parseInt(parsed[2]));
					dt.setFullYear(parseInt(parsed[0]));
					dt.setMonth(parseInt(parsed[1]) - 1);
					return dt;
				}

				function ppIsDateInPast(dateString) {
					var date = new Date(dateString);
					var now = new Date(ppGetDateString(new Date()));
					return (
						new Date(dateString).setHours(0, 0, 0, 0) <
						new Date(ppGetDateString(new Date())).setHours(0, 0, 0, 0)
					);
				}

				function ppSetEndDateMinimum() {
					var minimumDate = new Date(ppParseDateInput(ppStartDate.value));
					minimumDate.setDate(minimumDate.getDate() + 1);

					ppEndDate.setAttribute('min', ppGetDateString(minimumDate));
				}

				function ppGetSetStartDateMinimum() {
					var td = new Date();
					td.setHours(0, 0, 0, 0);
					var month = td.getMonth() + 1;
					var day = td.getDate();
					var year = td.getFullYear();
					if (month < 10) month = '0' + month.toString();
					if (day < 10) day = '0' + day.toString();
					return year + '-' + month + '-' + day;
				}

				function ppSetStartDate(elem) {
					var min = ppGetSetStartDateMinimum();
					elem.valueAsDate = new Date(min);
					elem.setAttribute('min', min);
				}

				function ppStartDateOnChange(e) {
					if (ppIsDateInPast(e.target.value)) {
						e.target.valueAsDate = new Date();
					}

					if (!ppEndDate.disabled) {
						if (!ppValidateStartAndEndDates()) {
							ppEndDate.value = new Date();
						}

						ppSetEndDateMinimum();
					}
				}

				function ppHideElement(elem) {
					if (!elem.classList.contains('pp-hidden')) {
						elem.classList.add('pp-hidden');
					}
				}

				function ppShowElement(elem) {
					if (elem.classList.contains('pp-hidden')) {
						elem.classList.remove('pp-hidden');
					}
				}

				function ppActivateElement(elem) {
					if (!elem.classList.contains('pp-active')) {
						elem.classList.add('pp-active');
					}
				}

				function ppDeactivateElement(elem) {
					if (elem.classList.contains('pp-active')) {
						elem.classList.remove('pp-active');
					}
				}

				function ppEndTypesOnChange(e) {
					ppOccurence.disabled = true;
					ppEndDate.disabled = true;

					switch (e.target.value) {
						case '1':
							ppHideElement(ppOccurenceContainer);
							ppShowElement(ppEndDateContainer);
							ppEndDate.disabled = false;
							ppSetEndDateMinimum();
							break;
						case '2':
							ppHideElement(ppEndDateContainer);
							ppShowElement(ppOccurenceContainer);
							ppOccurence.disabled = false;
							break;
						default:
							ppHideElement(ppOccurenceContainer);
							ppHideElement(ppEndDateContainer);
							break;
					}
				}

				function disableEndTypes(isOneTimeTabSelected) {
					ppEndTypes.disabled = isOneTimeTabSelected;

					if (isOneTimeTabSelected) {
						ppEndDate.disabled = isOneTimeTabSelected;
						ppOccurence.disabled = isOneTimeTabSelected;
					} else {
						const ppEndTypesDateOption = '1';
						const ppEndTypesOccurenceOption = '2';
						ppEndDate.disabled = ppEndTypes.value !== ppEndTypesDateOption;
						ppOccurence.disabled = ppEndTypes.value !== ppEndTypesOccurenceOption;
					}
				}

				function ppOnTabClick(e) {
					const tab = e.target;
					const tabId = tab.id;

					ppActivateElement(tab);
					if (tabId === ppTabOneTimeBtn.id) {
						ppDeactivateElement(ppTabRecurringBtn);
						ppHideElement(ppRecurringContainer);
						ppRecurrence.disabled = true;
						ppGiveOnce.disabled = false;
						ppStartDate.disabled = true;

						if (payerCanSpecifyEndType) {
							disableEndTypes(true);
						}
					} else {
						ppShowElement(ppRecurringContainer);
						ppDeactivateElement(ppTabOneTimeBtn);
						ppRecurrence.disabled = false;
						ppGiveOnce.disabled = true;
						ppStartDate.disabled = false;

						if (payerCanSpecifyEndType) {
							disableEndTypes(false);
						}
					}
				}

				function ppShowError(msg) {
					ppShowElement(ppFormErrors);
					ppFormErrors.innerText = msg;
				}

				function ppHideErrors() {
					ppFormErrors.innerText = '';
					ppHideElement(ppFormErrors);
				}

				function ppValidateAmount() {
					const amount = ppAmount.value;
					return parseInt(amount) > 0;
				}

				function ppValidateFund() {
					return ppFund.value ? true : false;
				}

				function ppValidateRecurrence() {
					return ppRecurrence.value ? true : false;
				}

				function ppAmountOnWheel(e) {
					e.target.blur();
				}

				function ppValidateOccurences() {
					if (ppOccurence.disabled) {
						return true;
					}

					if (ppOccurence.value > 1000 || ppOccurence.value < 1) {
						return false;
					}

					return true;
				}

				function ppValidateStartAndEndDates() {
					if (ppEndDate.disabled) {
						return true;
					}

					return (
						new Date(ppStartDate.value).setHours(0, 0, 0, 0) <
						new Date(ppEndDate.value).setHours(0, 0, 0, 0)
					);
				}

				function ppFormOnSubmit(e) {
					ppHideErrors();
					var ppFormHasErrors = false;
					const isRecurring = ppTabRecurringBtn.classList.contains('pp-active');

					if (!ppValidateAmount()) {
						ppShowError('Please enter an amount');
						ppFormHasErrors = true;
					}

					if (!ppValidateFund()) {
						ppShowError('Please select a fund');
						ppFormHasErrors = true;
					}

					if (isRecurring && !ppValidateRecurrence()) {
						ppShowError('Please select a frequency');
						ppFormHasErrors = true;
					}

					if (isRecurring && !!ppStartDate.value === false) {
						ppShowError('Please enter a valid start date');
						ppFormHasErrors = true;
					}

					if (isRecurring && ppIsDateInPast(ppParseDateInput(ppStartDate.value))) {
						ppShowError('Start date cannot be in the past');
						ppFormHasErrors = true;
					}

					if (payerCanSpecifyEndType) {
						if (isRecurring && !ppValidateOccurences()) {
							ppShowError('Please enter number of occurences between 1-1000');
							ppFormHasErrors = true;
						}

						if (isRecurring && !ppValidateStartAndEndDates()) {
							ppShowError('End date must be 1 day after start date or later');
							ppFormHasErrors = true;
						}
					}

					if (ppFormHasErrors) {
						e.preventDefault();
						return false;
					}
				}

				/* --- EVENT LISTENERS --- */
				ppTabOneTimeBtn.addEventListener('click', ppOnTabClick);
				ppTabRecurringBtn.addEventListener('click', ppOnTabClick);

				ppStartDate.addEventListener('change', ppStartDateOnChange);
				ppStartDate.addEventListener('click', function (event) {
					event.target.showPicker();
				});
				ppAmount.addEventListener('wheel', ppAmountOnWheel);
				ppForm.addEventListener('submit', ppFormOnSubmit);

				if (payerCanSpecifyEndType) {
					ppEndDate.addEventListener('change', ppStartDateOnChange);
					ppEndDate.addEventListener('click', function (event) {
						event.target.showPicker();
					});
					ppEndTypes.addEventListener('change', ppEndTypesOnChange);
				}
			}
		});
		`;
		return <script type="text/javascript" dangerouslySetInnerHTML={{ __html: html }} />;
	};

	private generateStyle = () => {
		const { Color } = this.props.formInfo;
		const primaryLightened = tinycolor.mix(Color, 'white', 15);
		const hoverColor = tinycolor.isReadable('#fff', primaryLightened, { level: 'AAA', size: 'small' })
			? primaryLightened.toHexString()
			: tinycolor.mix(Color, 'black', 15).toHexString();

		const html = `
			:root {
				--pp-primary: ${Color};
				--pp-primary-hover: ${hoverColor};
			}

			#pp-embeddedContainer {
				padding: 24px;
			}

			#pp-embeddedForm button,
			#pp-embeddedForm input,
			#pp-embeddedForm select {
				height: 40px;
			}

			#pp-amount,
			#pp-occurence {
				-moz-appearance: textfield;
			}

			#pp-amountBox {
				position: relative;
				display: flex;
				flex-direction: column;
				margin-bottom: 24px;
			}

			#pp-amountBox .pp-amountPrefix {
				position: absolute;
				line-height: 40px;
				left: 16px;
				bottom: 0px;
				height: 40px;
				color: #4b4d53;
				border: 1px transparent solid;
			}

			#pp-amount::-webkit-outer-spin-button,
			#pp-amount::-webkit-inner-spin-button {
				-webkit-appearance: none;
			}

			#pp-embeddedForm label {
				display: block;
				font-size: 14px;
				font-weight: 500;
				padding-bottom: 0px;
				color: #737478;
				margin-bottom: 8px;
			}

			#pp-embeddedForm input,
			#pp-embeddedForm select {
			    font-size: 16px;
				border: 1px solid #ccc;
				border-radius: 4px;
				display: block;
				padding: 0px;
				background-color: #fff;
				color: #4b4d53;
				cursor: pointer;
			}

			#pp-embeddedForm select,
			#pp-endDate,
			#pp-occurence,
			#pp-startDate {
				width: 100%;
				padding: 0px 0 0 10px;
				margin-bottom: 24px;
			}

			#pp-startDate,
			#pp-endDate,
			#pp-embeddedForm #pp-occurence {
				text-indent: 6px;
				padding-left: 6px;
			}

			#pp-amountBox input {
				text-indent: 28px;
				color: #4d4d4d;
			}

			#pp-formErrors {
				border: 1px red solid;
				border-radius: 4px;
				border-top-width: 4px;
				margin-bottom: 16px;
				padding: 10px;
				font-size: 13px;
			}

			#pp-embeddedForm input[type='date'] {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				align-content: center;
			}

			#pp-embeddedForm input[type='submit'] {
				border: none;
				width: 100%;
				cursor: pointer;
				border-radius: 45px;
				background-color: var(--pp-primary);
				color: #fff;
				text-indent: 0;
				min-width: 100px;
				min-height: 40px;
				margin: 0;
			}

			#pp-embeddedForm input[type='submit']:hover {
				background-color: var(--pp-primary-hover);
			}

			#pp-tabContainer button {
				flex: 1;
				font-size: 16px;
				background-color: transparent;
				border: 1px solid #ccc;
				padding: 10px;
				cursor: pointer;
				background-color: white;
				line-height: normal;
				transition: background-color 0.3s;
			}

			#pp-tabContainer button.pp-active {
				background-color: var(--pp-primary);
				color: white;
				border-color: var(--pp-primary);
			}

			#pp-tabContainer button.pp-active:hover {
				background-color: var(--pp-primary-hover);
			}

			.pp-hidden {
				display: none !important;
			}

			#pp-tabOneTime {
				border-radius: 4px 0 0 4px;
				margin-right: -1px;
			}

			#pp-tabRecurring {
				border-radius: 0 4px 4px 0;
			}

			#pp-oneTime.pp-active,
			#pp-recurring.pp-active {
				display: block;
			}

			#pp-embeddedForm .pp-required {
				color: red;
				position: relative;
				display: inline-block;
			}

			#pp-separator {
				text-align: center;
				position: relative;
				display: flex;
				align-items: center;
				height: 80px;
			}

			#pp-separator hr {
				border: none;
				height: 1px;
				background-color: #ccc;
				flex: 1;
			}

			#pp-separator span {
				padding: 0px 12px;
			}

			#pp-stockAndCrypto {
				text-align: center;
			}

			#pp-engivenLinks {
				display: flex;
				gap: 16px;
			}

			#pp-engivenLinks a {
				line-height: 16px;
				padding: 12px;
				border-radius: 24px;
				color: var(--pp-primary);
				text-decoration: none;
				border: 1px var(--pp-primary) solid;
				cursor: pointer;
				display: flex;
				flex: 1;
				text-align: center;
				justify-content: center;
			}

			#pp-engivenLinks a:hover {
				color: var(--pp-primary-hover);
				border: 1px var(--pp-primary-hover) solid;
			}

			#pp-engivenInfo {
				font-size: 14px;
				line-height: 18px;
				visibility: hidden;
				height: 0;
				overflow: hidden;
			}

			#pp-engivenInfo a {
				color: var(--pp-primary);
				padding: 0 4px;
			}

			#pp-engivenInfo p {
				margin: 0 0 16px;
			}

			#pp-stockAndCrypto:hover #pp-engivenInfo {
				visibility: visible;
				height: auto;
				padding: 24px 0;
			}

			@media (max-width: 576px) {
				#pp-embeddedContainer {
					padding: 24px 0;
				}

				#pp-engivenInfo {
					visibility: visible;
					height: auto;
					padding: 25px 0;
					line-height: 25px;
					font-size: 16px;
				}

				#pp-engivenInfo a,
				#pp-engivenInfo small {
					line-height: 25px;
					font-size: 16px;
				}
			}
		`;

		return <style type="text/css" dangerouslySetInnerHTML={{ __html: html }} />;
	};
}
