import * as React from 'react';
import { observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';

import { classNames } from '../../../../Shared/utils/classnames';
import { ModelMetadata } from '../../../branding-settings/branding-settings-generated';
import { IFormControlCommonProps } from '../form-control-common-props';
import { getElementId } from '../form-control-utils';
import { isFunction } from '../../../utils/is-function';
import { Fragment } from '../../../../Shared/components/fragment';

import { SvgWrapper } from '../../svg-wrapper';
import * as styles from './form-control-image-input.less';
import { ValidatableControl } from '../validatable-control';

export interface IFormControlImageInputProps extends IFormControlCommonProps {
	containerClassNames?: string;
	fileCurrent: FormControlImageState;
	fileAccept?: string;
	ariaLabel?: string;
	onChangeHandler?: (file: File | null) => void;
}

export const stateNoSelection: FormControlImageStateNoSelection = {
	uploadState: 'no-selection'
};

export type FormControlImageStateNoSelection = {
	uploadState: 'no-selection';
};

export type FormControlImageStateUploading = {
	uploadState: 'uploading';
	previousValue?: string;
	previousPreviewUrl?: string;
};

export type FormControlImageStateProcessing = {
	uploadState: 'processing';
	previousValue?: string;
	previousPreviewUrl?: string;
};

export type FormControlImageStateSuccess = {
	uploadState: 'success';
	previewUrl: string;
	value: string;
};

export type FormControlImageStateError = {
	uploadState: 'error';
	uploadError: string;
};

export type FormControlImageState =
	FormControlImageStateNoSelection |
	FormControlImageStateUploading |
	FormControlImageStateProcessing |
	FormControlImageStateSuccess |
	FormControlImageStateError;

export function isUploading(state: FormControlImageState): state is FormControlImageStateUploading {
	return state.uploadState === 'uploading';
}

export function isProcessing(state: FormControlImageState): state is FormControlImageStateProcessing {
	return state.uploadState === 'processing';
}

@observer
export class FormControlImageInput extends React.Component<IFormControlImageInputProps> {

	@observable private isDragOver = false;

	private inputRef: HTMLInputElement;

	render() {
		const {
			containerClassNames,
			acceptanceTestTargetId = '',
		} = this.props;

		return (
			<Fragment>
				<div
					className={classNames(containerClassNames)}
					data-pp-at-target={`${acceptanceTestTargetId} Container`}
					onDrag={this.onDrag}
					onDragOver={this.onDragOver}
					onDragLeave={this.onDragLeave}>
					{this.renderContent()}
				</div>
				{this.renderErrorMessage()}
			</Fragment>
		);
	}

	private renderContent() {
		const { fileCurrent } = this.props;
		let isUploadPanelVisible: boolean = true;

		switch (fileCurrent.uploadState) {
			case 'no-selection':
			case 'error':
				return this.renderUploadPanel(isUploadPanelVisible);
			case 'uploading':
			case 'processing':
				isUploadPanelVisible = false;
				return (
					<Fragment>
						{this.renderUploadPanel(isUploadPanelVisible)}
						{this.renderWaitingPanel(fileCurrent)}
					</Fragment>
				);
			case 'success':
				isUploadPanelVisible = false;
				return (
					<Fragment>
						{this.renderUploadPanel(isUploadPanelVisible)}
						{this.renderPreviewPanel(fileCurrent)}
					</Fragment>
				);
			default:
				const unsupported: never = fileCurrent;
				throw new Error(`Unsupported upload state: ${(unsupported as any).uploadState}`);
		}
	}

	private renderErrorMessage() {
		const { fileCurrent } = this.props;
		const hasError = fileCurrent.uploadState === 'error';

		if (hasError)
		{
			return (
				<span className={'field-validation-error ' + styles.errorText} data-chromatic="ignore">
					{(fileCurrent as FormControlImageStateError).uploadError}
				</span>
			);
		}
	}

	private renderUploadPanel = (visible: boolean) => {
		const {
			name,
			disabled,
			fileCurrent,
			acceptanceTestTargetId = '',
			fileAccept = 'image/*',
			tabIndex,
			uniqueSuffix,
			ariaLabel,
			validationRules,
		} = this.props;

		const id = getElementId(name, uniqueSuffix);
		const hasError = fileCurrent.uploadState === 'error';

		return (
			<Fragment>
				<label
					htmlFor={id}
					className={classNames('upload-area', styles.uploadArea, this.isDragOver && styles.active, hasError && styles.error, !visible && styles.hidden)}
					onDrop={this.onDrop}
				>
					<ImageUploadAreaBorder active={!!this.isDragOver} />
					<SvgWrapper className={styles.image} svg="image-placeholder" />
					<span className={styles.content}>{'Drag and drop an image or '}</span>
					<span className={styles.link} tabIndex={0} onKeyPress={this.handleSpaceEnterKeyPress(() => this.inputRef.click())}>browse your files</span>
					<ValidatableControl
						validationRules={validationRules}
						elementName={name}
						validateOnChange={true}
					>
						<input
							type="file"
							accept={fileAccept}
							onClick={this.handleClick}
							onChange={this.onFileChangeHandler}
							id={id}
							name={name}
							className={styles.input}
							disabled={disabled}
							tabIndex={tabIndex}
							data-pp-at-target={acceptanceTestTargetId}
							aria-label={ariaLabel}
							ref={ref => this.inputRef = ref}
						/>
					</ValidatableControl>
				</label>
			</Fragment>
		);
	}

	private renderWaitingPanel = (state: FormControlImageStateUploading | FormControlImageStateProcessing) => (
		<div className={classNames('waiting-container', styles.waitingContainer)} onDrop={this.onDrop}>
			<span className={styles.waitingText}>{ state.uploadState === 'uploading' ? 'Uploading' : 'Processing' }</span>
			{this.renderRemoveImageButton()}
		</div>
	)

	private renderPreviewPanel = (state: FormControlImageStateSuccess) => {
		const { name } = this.props;
		const imageContainerBackground = name === ModelMetadata.BrandingPackageEditModel.FaviconImageKey.propertyName ?
			styles.browserTabBackground : styles.checkeredBackground;

		return (
			<div className={classNames('image-container', styles.imageContainer, imageContainerBackground)} onDrop={this.onDrop}>
				<img src={state.previewUrl} className={classNames('preview-image', styles.previewImage)} />
				{this.renderRemoveImageButton()}
			</div>
		);
	}

	private renderRemoveImageButton = () => {
		const { acceptanceTestTargetId = '' } = this.props;
		return (
			<button className={styles.close} onClick={this.removeExistingImage} data-pp-at-target={acceptanceTestTargetId + ' Remove Button'}>
				<SvgWrapper className={styles.closeIcon} svg="close-cross-small" title="Remove" />
			</button>
		);
	}

	private handleClick = (evt: React.FormEvent<HTMLInputElement>) => {
		// Choose the same image file doesn't trigger onChange event, the user might modify an image file to meet upload requirements,
		// we need to allow them to upload in this case, so we clear input value each time the user wants to select an image, which makes
		// the onChange event is always triggered even if the user selects the same image file.
		evt.currentTarget.value = '';
	}

	private onFileChangeHandler = (evt: React.FormEvent<HTMLInputElement>) => {
		this.onChangeFile(evt.currentTarget.files);
	}

	private onDrop = (evt: React.DragEvent<HTMLElement>) => {
		evt.preventDefault();
		this.isDragOver = false;

		// Remove the existing image
		this.removeExistingImage();

		// Change this.inputRef.files programmatically and trigger input change event, so jQuery validation is called.
		// Note: assignment of this.inputRef.files works in Chrome, Safari and Firefox, but not in Edge, IE 11. Edge will
		// have a fix for it soon, see https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/18069907/.
		const prototype = Object.getPrototypeOf(this.inputRef);
		if (Object.getOwnPropertyDescriptor(prototype, 'files').set) {
			this.inputRef.files = evt.dataTransfer.files;
			const changeEvent = document.createEvent('Event');
			changeEvent.initEvent('change', true, true);
			this.inputRef.dispatchEvent(changeEvent);
		} else { // In the case when the assignment of this.inputRef.files doesn't work, it relies on the server side validation.
			this.onChangeFile(evt.dataTransfer.files);
		}
	}

	private onChangeFile(files: FileList) {
		const { onChangeHandler } = this.props;
		if (files.length === 0 || !files[0] || !isFunction(onChangeHandler)) {
			return;
		}
		onChangeHandler(files[0]);
	}

	private removeExistingImage = () => {
		const { onChangeHandler } = this.props;
		if (isFunction(onChangeHandler)) {
			this.props.onChangeHandler(null);
		}
	}

	private onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();

		const { fileCurrent: { uploadState } } = this.props;
		if (uploadState === 'no-selection' || uploadState === 'error') {
			this.isDragOver = true;
		}
	}

	private onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();

		const { fileCurrent: { uploadState } } = this.props;
		if (uploadState === 'no-selection' || uploadState === 'error') {
			this.isDragOver = false;
		}
	}

	private onDrag = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
	}

	private handleSpaceEnterKeyPress = (callback: () => void) => (evt: React.KeyboardEvent<any>) => {
		if (evt.key === 'Space' || evt.key === 'Enter') {
			callback();
		}
	}
}

@observer
class ImageUploadAreaBorder extends React.Component<{ active: boolean }> {

	private borderContainer: SVGElement;

	@observable
	private borderSettings = {
		rx: 5,
		ry: 5,
		height: 0,
		width: 0
	};

	changeBorderSettings = () => {
		runInAction(() => {
			const { clientHeight, clientWidth } = this.borderContainer;
			// Firefox couldn't resolve client width and height of an svg element, use parent node's to resolve it.
			const { clientHeight: parentClientHeight, clientWidth: parentClientWidth } = this.borderContainer.parentNode as HTMLElement;
			this.borderSettings.width = clientWidth || parentClientWidth;
			this.borderSettings.height = clientHeight || parentClientHeight;
		});
	}

	componentDidMount() {
		this.changeBorderSettings();
		window.addEventListener('resize', this.changeBorderSettings);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.changeBorderSettings);
	}

	render() {
		const { width, height } = this.borderSettings;
		return (
			<svg
				className={classNames('upload-area-border', styles.border)}
				style={{ transform: `scale(${this.getScale(width)}, ${this.getScale(height)})` }}
				ref={element => this.borderContainer = element}
			>
				<rect {...this.borderSettings} />
			</svg>
		);
	}

	private getScale(size): number {
		if (!this.props.active || !size) {
			return 1;
		}
		const activeMargin = 10;
		return (size - activeMargin) / size;
	}
}
