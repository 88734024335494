import * as React from 'react';
import { AutocompleteStore, Autocomplete } from '../../components/autocomplete';
import { ResponseTypes } from '../../funds/funds-generated';
import getIntegrationFundsForDisplay from '../helpers/get-integration-funds-for-display';

export class IntegrationFundAutocompleteStore extends AutocompleteStore {
	constructor(integrationFunds: ResponseTypes.IntegrationFundViewModel[], selectedValue: string) {
		const autocompleteItems = getIntegrationFundsForDisplay(integrationFunds);
		super({ Items: autocompleteItems, SelectedValue: selectedValue });
	}
}

export interface IIntegrationFundAutocompleteProps {
	autocompleteStore: IntegrationFundAutocompleteStore;
	emptyText?: string;
	floatingLabelText?: string;
	fieldName?: string;
	validationRules?: any;
	validateOnInit?: boolean;
	disabled?: boolean;
}

export class IntegrationFundAutocomplete extends React.Component<IIntegrationFundAutocompleteProps, {}> {
	render() {
		return <Autocomplete {...this.props} />;
	}
}
