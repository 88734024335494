import * as React from 'react';
import { ValidatableForm } from '../../components/form-controls/validatable-form';
import { FormControlLabel } from '../../components/form-controls/form-control-label';
import { FormControlInput } from '../../components/form-controls/form-control-input';
import { FormControlTextarea } from '../../components/form-controls/form-control-textarea';
import { ValidationMessage } from '../../components/form-controls/validation-message';
import { InputField } from '../../funds/components/form-controls';
import { Button } from '../../components/button';
import { ModalDialogCommander } from '../../components/modal-dialog-commander';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { AlertControl, AlertProps, AlertType } from '../components/alert-control';
import { Autocomplete, AutocompleteStore } from '../../components/autocomplete';
import getIntegrationFundsForDisplay from '../../integrations/helpers/get-integration-funds-for-display';
import { FormControlMultiSelect } from '../../components/form-controls/form-control-multi-select';
import { MultiSelectItem } from '../../loggedinweb-generated';
import { QuickBooksManageFundInfo } from '../../transaction-import/transaction-import-generated';

export interface PropertyMetadata {
	propertyName: string;
	displayName?: string;
	placeholder?: string;
	validationRules?: { [rule: string]: any };
}

export interface NewFundMetadata {
	FundName: PropertyMetadata;
	Code: PropertyMetadata;
	TaxDeductible: PropertyMetadata;
	Notes: PropertyMetadata;
}

export interface IntegrationFundViewModel {
	Key: string;
	Code: string;
	Name: string;
	ParentFundKey: string;
}

export interface IntegrationFundsInfo {
	DisplayName: string;
	ExternalSystemId: number;
	IntegrationFunds: IntegrationFundViewModel[];
}

export class AddNewFundViewModel {
	@observable fundName: string;
	@observable code: string;
	@observable notes: string;
	@observable taxDeductible?: boolean = null;
	@observable addFundInProgress: boolean = false;
	@observable alertProps: AlertProps;

	@observable selectedMerchants: string[];
	availableListings: MultiSelectItem[];
	currentMerchantId: number;

	integrationFundProps: IntegrationFundRowProp[];
	quickBooksConfigurationProps: QuickBooksConfigurationRowProp[];
	fundCodePlaceholderText: string;
	payerLabel: string;

	metadata: NewFundMetadata;
	
	hasCcbIntegration: boolean;

	constructor(
		fundCodePlaceholderText: string,
		payerLabel: string,
		integrationFundsInfo: IntegrationFundsInfo[],
		quickBooksManageFundInfo: QuickBooksManageFundInfo,
		metadata: NewFundMetadata,
		hasCcbIntegration: boolean,
		availableListings?: MultiSelectItem[],
		currentMerchantId?: number
	) {
		this.fundCodePlaceholderText = fundCodePlaceholderText;
		this.payerLabel = payerLabel;
		this.metadata = metadata;
		this.hasCcbIntegration = hasCcbIntegration;
		this.integrationFundProps = integrationFundsInfo ? integrationFundsInfo.map(x => {
			const autoCompleteItems = getIntegrationFundsForDisplay(x.IntegrationFunds);
			return {
				externalSystemId: x.ExternalSystemId,
				displayName: x.DisplayName,
				integrationFundStore: new AutocompleteStore({Items: autoCompleteItems, SelectedValue: null})
			};
		}) : [];

		this.initializeQuickBooksConfiguration(quickBooksManageFundInfo);

		this.availableListings = availableListings;
		this.selectedMerchants = currentMerchantId ? [currentMerchantId.toString()] : [];
		this.currentMerchantId = currentMerchantId;
	}

	initializeQuickBooksConfiguration(quickBooksManageFundInfo: QuickBooksManageFundInfo) {
		this.quickBooksConfigurationProps = [];
		if (quickBooksManageFundInfo) {
			const accountItems = getIntegrationFundsForDisplay(quickBooksManageFundInfo.Configuration.Accounts);
			const integrationAccountInfo = {
				externalConfigurationId: quickBooksManageFundInfo.IntegrationMapping.AccountId,
				displayName: 'Account',
				integrationConfigurationStore: new AutocompleteStore({ Items: accountItems, SelectedValue: null }),
				isRequired: true,
				usesRegisteredSymbol: true,
			};
			this.quickBooksConfigurationProps.push(integrationAccountInfo);

			if (quickBooksManageFundInfo.CanClassesBeConfigured) {
				const classItems = getIntegrationFundsForDisplay(quickBooksManageFundInfo.Configuration.Classes);
				const integrationClassInfo = {
					externalConfigurationId: quickBooksManageFundInfo.IntegrationMapping.ClassId,
					displayName: 'Class',
					integrationConfigurationStore: new AutocompleteStore({ Items: classItems, SelectedValue: null }),
					isRequired: false,
					usesRegisteredSymbol: false,
				};
				this.quickBooksConfigurationProps.push(integrationClassInfo);
			}

			if (quickBooksManageFundInfo.CanLocationsBeConfigured) {
				const locationItems = getIntegrationFundsForDisplay(quickBooksManageFundInfo.Configuration.Locations);
				const integrationLocationItem = {
					externalConfigurationId: quickBooksManageFundInfo.IntegrationMapping.LocationId,
					displayName: 'Location',
					integrationConfigurationStore: new AutocompleteStore({ Items: locationItems, SelectedValue: null }),
					isRequired: false,
					usesRegisteredSymbol: false,
				};
				this.quickBooksConfigurationProps.push(integrationLocationItem);
			}
		}
	}

	@action.bound
	updateSelectedMerchants(selectedMerchants: string[]) {
		this.selectedMerchants = selectedMerchants;
	}

	@action.bound
	updateFundName(fundName: string) {
		this.fundName = fundName;
	}

	@action.bound
	updateCode(code: string) {
		this.code = code;
	}

	@action.bound
	updateNotes(notes: string) {
		this.notes = notes;
	}

	@action.bound
	updateTaxDeductible(taxDeductible: boolean) {
		this.taxDeductible = taxDeductible;
	}

	@action.bound
	clearFields() {
		this.fundName = null;
		this.code = null;
		this.notes = null;
		this.taxDeductible = null;
		this.alertProps = null;
		this.integrationFundProps.map(f => {
			f.integrationFundStore.selectedItem = null;
			f.integrationFundStore.searchTerm = null;
		});
		this.quickBooksConfigurationProps.map(c => {
			c.integrationConfigurationStore.selectedItem = null;
			c.integrationConfigurationStore.searchTerm = null;
		});
		this.selectedMerchants = this.currentMerchantId ?  [this.currentMerchantId.toString()] : [];
	}

	@action.bound
	showAlert(type: AlertType, content: string | string[]) {
		this.alertProps = {
			alertType: type,
			alertContent: content,
			showCloseButton: true,
			onClose: () => {
				this.alertProps = null;
			}
		};
	}

	openDialog(newFundDialog: React.ReactElement<AddNewFundDialog>) {
		this.clearFields();
		ModalDialogCommander.showReactDialog(newFundDialog);
	}

	closeDialog() {
		ModalDialogCommander.forceCloseCurrent();
	}

	get matchedIntegrationFunds() {
		return this.integrationFundProps.map(fund => ({
			ExternalSystemId: fund.externalSystemId,
			IntegrationFundKey: fund.integrationFundStore.selectedValue,
		}));
	}

	get matchedQuickBooksConfiguration() {
		if (this.quickBooksConfigurationProps.length === 0) {
			return null;
		}

		const accountConfiguration = this.quickBooksConfigurationProps.filter(x => x.displayName === 'Account')[0];
		const classConfiguration = this.quickBooksConfigurationProps.filter(x => x.displayName === 'Class')[0];
		const locationConfiguration = this.quickBooksConfigurationProps.filter(x => x.displayName === 'Location')[0];

		return {
			FundId: null,
			AccountId: accountConfiguration.integrationConfigurationStore.selectedValue,
			ClassId: classConfiguration ? classConfiguration.integrationConfigurationStore.selectedValue : null,
			LocationId: locationConfiguration ? locationConfiguration.integrationConfigurationStore.selectedValue : null
		};
	}
}

@observer
export class AddNewFundDialog extends React.Component<{
	vm: AddNewFundViewModel,
	hasCcbIntegration: boolean,
	handleAddNewFund: () => void}, {}> {

	render() {

		const {
			fundName,
			code,
			notes,
			taxDeductible,
			addFundInProgress,
			alertProps,
			fundCodePlaceholderText,
			payerLabel,
			integrationFundProps,
			quickBooksConfigurationProps,
			metadata,
			selectedMerchants,
			availableListings
		} = this.props.vm;
		
		const hasCcbIntegration = this.props.hasCcbIntegration;

		return (
			<div className="modal modal-form fade" tabIndex={-1} role="dialog" data-modal-prevent-close={addFundInProgress} >
				<ValidatableForm onSubmit={this.handleSubmit} className="panel panel-default form-horizontal modal-dialog">
					<Button className="modal-form-close" data-dismiss="modal" aria-label="Close"/>
					<div className="panel-heading">
					New fund details
					</div>
					<div className="panel-body">
						<AlertControl {...alertProps}/>

						<div className="form-group">
							<div className="col-sm-12">
								<FormControlLabel
									label={metadata.FundName.displayName}
									elementName={metadata.FundName.propertyName} />
							</div>
							<div className="col-sm-6">
								<FormControlInput
									name={metadata.FundName.propertyName}
									type="text"
									value={fundName}
									placeholder={metadata.FundName.displayName}
									onChangeHandler={this.handleFundNameChange}
									validationRules={metadata.FundName.validationRules} />
							</div>
							<div className="col-sm-6">
								<ValidationMessage elementName={metadata.FundName.propertyName} />
							</div>
						</div>

						<div className="form-group">
							<div className="col-sm-12">
								<FormControlLabel
									label={metadata.Code.displayName}
									elementName={metadata.Code.propertyName}
									tooltipOptions={{
										message: `The Fund code is what will be populated in your transaction exports.
													If you need a specific value exported (i.e the matching ${fundCodePlaceholderText} for your Church Management System), enter this here.
													The Fund code is not displayed to ${payerLabel}.`,
										placement: 'top',
									}} />
							</div>
							<div className="col-sm-6">
								<FormControlInput
									name={metadata.Code.propertyName}
									type="text"
									value={code}
									placeholder={fundCodePlaceholderText}
									onChangeHandler={this.handleCodeChange}
									validationRules={metadata.Code.validationRules}
									ignorePanLikeValidation={true}/>
							</div>
							<div className="col-sm-6">
								<ValidationMessage elementName={metadata.Code.propertyName} />
							</div>
						</div>

						<div className="form-group">
							<div className="col-sm-12">
								<FormControlLabel
									label={metadata.TaxDeductible.displayName}
									elementName={metadata.TaxDeductible.propertyName}
									tooltipOptions={{
										message: `Indicates whether the fund will be used for a tax deductible purpose.
													This setting will define whether transactions made to this fund are included in giving statements.`,
										placement: 'top'}}/>
							</div>
							<div className="col-sm-6">
								<label className="radio-inline">
									<InputField type="radio" propertyMetadata={metadata.TaxDeductible} value={'true'} checked={taxDeductible}
										onChange={this.checkDeductible} />
									<span className="radio-inner">Yes</span>
								</label>
								<label className="radio-inline">
									<InputField type="radio" propertyMetadata={metadata.TaxDeductible} value={'false'} checked={taxDeductible === false}
										onChange={this.uncheckDeductible} />
									<span className="radio-inner">No</span>
								</label>
							</div>
							<div className="col-sm-6">
								<ValidationMessage elementName={metadata.TaxDeductible.propertyName} />
							</div>
						</div>

						<div className="form-group">
							<div className="col-sm-12">
								<FormControlLabel
									label={metadata.Notes.displayName}
									elementName={metadata.Notes.propertyName} />
							</div>
							<div className="col-sm-6">
								<FormControlTextarea
									name={metadata.Notes.propertyName}
									value={notes}
									rows={4}
									placeholder={metadata.Notes.displayName}
									onChangeHandler={this.handleNotesChange}
									validationRules={metadata.Notes.validationRules}/>
							</div>
							<div className="col-sm-6">
								<ValidationMessage elementName={metadata.Notes.propertyName} />
							</div>
						</div>
						{
							hasCcbIntegration && NewFeatures.EnableStaqFunds && (
								<div className="form-group">
									<div className="col-sm-12">
										<FormControlLabel
											label={"Pushpay ChMS fund"}
											elementName={"Chms fund info label"}
											tooltipOptions={{
												message: 'Fund will be automatically created in ChMS',
												placement: 'top'
											}} />
									</div>
								</div>
							)
						}
						{
							integrationFundProps.map( integrationFundProp => (
								<IntegrationFundRow key={integrationFundProp.externalSystemId} {...integrationFundProp}/>
							))
						}
						{
							quickBooksConfigurationProps.map( quickBooksConfigurationProp => (
								<QuickBooksConfiguration key={quickBooksConfigurationProp.externalConfigurationId} {...quickBooksConfigurationProp}/>
							))
						}
						{availableListings && availableListings.length > 0 && <div className="form-group">
							<div className="col-sm-12">
								<FormControlLabel
									label="Listings"
									elementName="listings" />
							</div>
							<div className="col-sm-6">
								<FormControlMultiSelect
									name={'listings'}
									items={availableListings}
									onChangeHandler={this.handleMerchantChange}
									selectedValues={selectedMerchants}/>
							</div>
						</div>}

					</div>
					<div className="panel-footer panel-footer-btn-group">
						<Button className="btn btn-ghost" data-dismiss="modal" disabled={addFundInProgress}>Cancel</Button>
						<Button submit={true} className="btn btn-default" disabled={addFundInProgress}>Save</Button>
					</div>
				</ValidatableForm>
			</div>
		);
	}

	private handleMerchantChange = (selectedMerchants: string[]) => {
		this.props.vm.updateSelectedMerchants(selectedMerchants);
	}

	private handleFundNameChange = (event: React.FormEvent<HTMLInputElement>) => {
		const value = event.currentTarget.value;
		this.props.vm.updateFundName(value);
	}

	private handleCodeChange = (event: React.FormEvent<HTMLInputElement>) => {
		const value = event.currentTarget.value;
		this.props.vm.updateCode(value);
	}

	private handleNotesChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
		const value = event.currentTarget.value;
		this.props.vm.updateNotes(value);
	}

	private checkDeductible = () => {
		this.props.vm.updateTaxDeductible(true);
	}

	private uncheckDeductible = () => {
		this.props.vm.updateTaxDeductible(false);
	}

	private handleSubmit = () => {
		const {handleAddNewFund} = this.props;
		if (handleAddNewFund) {
			handleAddNewFund();
		}
	}
}

interface IntegrationFundRowProp {
	externalSystemId: number;
	displayName: string;
	integrationFundStore: AutocompleteStore;
}

const IntegrationFundRow: React.StatelessComponent<IntegrationFundRowProp> = (props) => {
	const { externalSystemId, displayName, integrationFundStore } = props;
	const elementName = `externalsystem_${externalSystemId}`;
	return (
		<div className="form-group">
			<div className="col-sm-12">
				<FormControlLabel
					label={displayName}
					elementName={elementName}
					tooltipOptions={{
						message: `Select the matching fund within ${displayName}. Pushpay transactions will be assigned to this fund when we generate the contribution in ${displayName}`,
						placement: 'top'}}/>
			</div>
			<div className="col-sm-6">
				<Autocomplete
					emptyText="- Select matching fund -"
					autocompleteStore={integrationFundStore}
					fieldName={elementName}
					validationRules={{required: {errorMessage: 'Please select a matching fund'}}}/>
			</div>
			<div className="col-sm-6">
				<ValidationMessage elementName={elementName} />
			</div>
		</div>
	);
};

interface QuickBooksConfigurationRowProp {
	externalConfigurationId: string;
	displayName: string;
	integrationConfigurationStore: AutocompleteStore;
	isRequired: boolean;
	usesRegisteredSymbol: boolean;
}

const QuickBooksConfiguration: React.StatelessComponent<QuickBooksConfigurationRowProp> = (props) => {
	const { displayName, integrationConfigurationStore, isRequired, usesRegisteredSymbol } = props;
	const lowerCaseDisplayName = displayName.toLowerCase();
	const elementName = `qbo_${lowerCaseDisplayName}`;
	return (
		<div className="form-group">
			<div className="col-sm-12">
				<FormControlLabel
					label={`QuickBooks` + (usesRegisteredSymbol ? `\xAE` : ``) + ` ${displayName}`}
					elementName={elementName}
					tooltipOptions={{
						message: `Select the matching ${lowerCaseDisplayName} within QuickBooks. `
							+ `Pushpay settlements will be assigned to this ${lowerCaseDisplayName} when we generate the journal entry in QuickBooks.`,
						placement: 'top'}}/>
			</div>
			<div className="col-sm-6">
				<Autocomplete
					emptyText={`- Select matching ${lowerCaseDisplayName} -`}
					autocompleteStore={integrationConfigurationStore}
					fieldName={elementName}
					validationRules={ isRequired ? {required: {errorMessage: `Please select a matching ${lowerCaseDisplayName}`}} : null}/>
			</div>
			<div className="col-sm-6">
				<ValidationMessage elementName={elementName} />
			</div>
		</div>
	);
};
