import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { isChromatic } from '../../../Shared/utils/is-chromatic';
import { isFunction } from '../../utils/is-function';
import { Debouncer } from '../../utils/debouncer';
import { AccessibilityCombobox } from '../hoc-accessibility/accessibility-combobox';
import { SelectableListKeyboardController } from '../hoc-behavior/selectable-list-keyboard-controller';
import { OmniboxContextKey, IHaveOmniboxContext, OmniboxFieldName } from './payer-search-omnibox-context';
import { inject, observer } from 'mobx-react';
import { responsiveHelper } from '../../helpers/responsive-helper';
import { WithAnalytics, recordEvent, injectAnalytics } from '../../analytics';

@injectAnalytics
@inject(OmniboxContextKey)
@observer
export class PayerSearchInputField extends React.Component<IHaveOmniboxContext & WithAnalytics & {
	value: string;
	placeholder?: string;
	onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
	onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
	focusOnMount?: boolean;
	onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
}, {}> {
	input: HTMLInputElement;

	recordSearch: Debouncer<void>;

	constructor(props) {
		super(props);
		if (NewFeatures.SetupPinpointAnalytics && this.props.analytics) {
			this.recordSearch = new Debouncer(250, () => {
				const { analytics: { feature, subFeature }, value } = this.props;
				if (value) {
					recordEvent({ feature, subFeature, eventTypeLabel: 'searchGiverName' });
				}
			})
		}
	}

	UNSAFE_componentWillMount() {
		if (this.props.focusOnMount && !isChromatic()) {
			this.props.omniboxContext.setFocused(true);
		}
	}

	render() {
		const {placeholder,
			value,
			onKeyDown,
			omniboxContext: {
				omniboxFocused,
				open,
				handleCurrentItemSelected,
				handleItemHighlightedByKeyboard,
				componentId,
				listId,
				activeItemId
			}} = this.props;

		return (
			<SelectableListKeyboardController
				disabled={!open}
				onCurrentItemSelected={handleCurrentItemSelected}
				onNavigated={handleItemHighlightedByKeyboard}>
				<AccessibilityCombobox id={componentId} owns={open ? listId : null} expanded={open} activedescendant={activeItemId} autocomplete="list">
					<input placeholder={placeholder}
						className="form-control"
						type="text"
						name={OmniboxFieldName}
						value={value}
						onFocus={this.handleFocus}
						onBlur={this.handleBlur}
						onKeyDown={onKeyDown}
						ref={ref => {
							this.input = ReactDOM.findDOMNode(ref) as HTMLInputElement;
							if (this.input && omniboxFocused) {
								this.input.focus();
							}
						}}
						onChange={this.handleChange}
						autoComplete="off"
						data-pp-at-target="payer search input"/>
				</AccessibilityCombobox>
			</SelectableListKeyboardController>
		);
	}

	handleBlur = () => {
		this.props.omniboxContext.setFocused(false);
	}

	handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
		const { omniboxContext, onFocus } = this.props;

		omniboxContext.setFocused(true);
		this.scrollToTop();

		if (onFocus && isFunction(onFocus)) {
			onFocus(event);
		}
	}

	handleChange = (event: React.FormEvent<HTMLInputElement>) => {
		if (NewFeatures.SetupPinpointAnalytics && this.recordSearch) {
			this.recordSearch.exec();
		}

		this.props.onChange(event);
		this.props.omniboxContext.handleRequestOpen();
	}

	private scrollToTop = () => {
		if (responsiveHelper.isXs) {
			this.input.scrollIntoView(true);
			const bannerHeightPlusPadding = 100;
			window.scrollBy(null, -bannerHeightPlusPadding);
		}
	}
}
