

// ReSharper disable InconsistentNaming
// ReSharper disable StringLiteralWrongQuotes
/* tslint:disable:no-use-before-declare quotemark max-line-length */
import { IApiAction } from '../utils/data-service';
export interface TransactionImportCreateRequestModel {
	ImportType: TransactionImportType;
	MerchantId: number;
}
export enum TransactionImportType {
	Unknown = 0, Pushpay = 1, FellowshipOneX1050 = 2, PushpayAndDonors = 3, ChurchCommunityBuilder = 4,
}
export interface TransactionImportUploadStatusResultModel {
	UploadStatus: TransactionImportUploadStatus;
	RejectedRows: RejectedRowInfo[];
}
export interface RejectedRowInfo {
	RowNumber: number;
	ErrorMessage: string;
}
export enum TransactionImportUploadStatus {
	Unknown = 0, WaitingForUpload = 1, Uploading = 2, Validating = 3, Completed = 4, Invalid = 5, Failed = 6,
}
export interface TransactionImportNewUploadModel {
	TransactionImportId: number;
	FileSize: number;
	FileName: string;
	Tags: string[];
}
export interface TransactionImportNewUploadResultModel {
	UploadLink: string;
}
export interface TransactionImportLoadSkippedRowsRequestModel {
	TransactionImportId: number;
	CurrentPage: number;
}
export interface TransactionImportLoadSkippedRowsResultModel {
	Result: OperationResult;
	ErrorMessage: string;
	SkippedRows: SkippedRowInfo[];
	CanLoadMoreSkippedRows: boolean;
	TotalSkippedRowCount: number;
}
export interface SkippedRowInfo {
	RowNumber: number;
	Reason: string;
	DuplicatePaymentId: number;
	DuplicatePaymentUrl: string;
	Amount: number;
}
export enum OperationResult {
	Failed = 0, Success = 1,
}
export interface TransactionImportConfirmRequestModel {
	TransactionImportId: number;
	TimezoneId: string;
	SendInvitationEmail: boolean;
}
export interface TransactionImportProgressResultModel {
	Result: OperationResult;
	ImportStatus: ImportStatusForUI;
	PercentComplete: number;
	NewPayersCount: number;
	ImportedAmount: number;
	SkippedAmount: number;
}
export enum ImportStatusForUI {
	NotStarted = 0, Importing = 1, Completed = 2,
}
export interface ImportNewFundModel {
	FundName: string;
	Code: string;
	TaxDeductible: boolean;
	Notes: string;
	IntegrationFundMatches: FundMatch[];
	QuickBooksIntegrationMapping: QuickBooksIntegrationMapping;
}
export interface QuickBooksIntegrationMapping {
	FundId: number;
	AccountId: string;
	ClassId: string;
	LocationId: string;
}
export interface FundMatch {
	IntegrationFundKey: string;
	ExternalSystemId: number;
}
export interface TransactionImportAddNewFundResultModel {
	Result: OperationResult;
	ErrorMessage: string;
	NewFund: PushpayFundModel;
}
export interface PushpayFundModel {
	PushpayFundId: number;
	PushpayFundDisplayName: string;
}
export interface TransactionImportMatchFundsModel {
	TransactionImportId: number;
	ImportedFunds: ImportedFundViewModel[];
}
export interface ImportedFundViewModel {
	ImportFundId: number;
	ImportFundName: string;
	PushpayFundId: number;
	FundMatchStatus: TransactionImportFundMatchStatus;
}
export enum TransactionImportFundMatchStatus {
	Unmatched = 0, Matching = 1, AutoMatched = 2, NoMatch = 3, ManuallyMatchedToExistingFund = 4, ManuallyMatchedToNewFund = 5,
}
export interface TransactionImportOperationResultModel {
	Result: OperationResult;
	ErrorMessage: string;
	SuccessUrl: string;
}
export interface TransactionImportUploadViewModel {
	PaymentLabel: PaymentLabel;
	ImportId: number;
	ImportType: TransactionImportType;
	UploadStatus: TransactionImportUploadStatus;
	CancelUrl: string;
	BackUrl: string;
	RejectedRows: RejectedRowInfo[];
}
export interface PaymentLabel {
	NounSentenceCase: string;
	NounPluralSentenceCase: string;
	VerbSentenceCase: string;
	VerbAppliedSentenceCase: string;
	NounLowerCase: string;
	NounPluralLowerCase: string;
	VerbLowerCase: string;
	VerbAppliedLowerCase: string;
	VerbPresentTenseSentenceCase: string;
	VerbPresentTenseLowerCase: string;
	VerbPastTenseSentenceCase: string;
	VerbPastTenseLowerCase: string;
	PayerSentenceCase: string;
	PayerLowerCase: string;
	PayerPluralSentenceCase: string;
	PayerPluralLowerCase: string;
	ActionSentenceCase: string;
	ActionLowerCase: string;
	ActionPluralSentenceCase: string;
	ActionPluralLowerCase: string;
	NonCashNounSentenceCase: string;
	NonCashNounLowerCase: string;
	NonCashPluralSentenceCase: string;
	NonCashPluralLowerCase: string;
	TextGivingFeatureName: string;
	GiftEntryFeatureName: string;
}
export interface TransactionImportCreateViewModel {
	AllImportTypes: SelectItem[];
	AllVisibleListings: SelectItem[];
	CancelUrl: string;
}
export interface SelectItem {
	Value: string;
	Label: string;
}
export interface TransactionImportSummaryViewModel {
	TransactionImportId: number;
	ImportStatus: ImportStatusForUI;
	ListingName: string;
	PaymentLabel: PaymentLabel;
	ImportType: string;
	TransactionPeriod: string;
	TimezoneId: string;
	TotalAmount: number;
	ImportedAmount: number;
	SkippedAmount: number;
	TransactionsCount: number;
	PayersCount: number;
	NewPayersCount: number;
	FundsCount: number;
	NewFundsCount: number;
	Date: string;
	ImportedBy: string;
	BackUrl: string;
	IndexUrl: string;
	SendInvitationEmail: boolean;
	CanLoadMoreSkippedRows: boolean;
	TotalSkippedRowCount: number;
	SkippedRows: SkippedRowInfo[];
	Funds: FundSummaryInfo[];
	AvailableTimezones: SelectItem[];
	SupportUrl: string;
}
export interface FundSummaryInfo {
	ImportedFundName: string;
	PushpayFundName: string;
	PushpayFundCode: string;
	Notes: string;
	Amount: number;
}
export interface TransactionImportFundsViewModel {
	TransactionImportId: number;
	MerchantId: number;
	PushpayFunds: PushpayFundModel[];
	ImportedFunds: ImportedFundViewModel[];
	FundCodePlaceholderText: string;
	PayerLabel: string;
	CancelUrl: string;
	IntegrationFundsInfo: IntegrationFundsInfo[];
	QuickBooksManageFundInfo: QuickBooksManageFundInfo;
	HasCcbIntegration: boolean;
}
export interface QuickBooksManageFundInfo {
	DisplayName: string;
	DisplayNameFirstUse: string;
	CanLocationsBeConfigured: boolean;
	CanClassesBeConfigured: boolean;
	Configuration: QuickBooksExternalConfiguration;
	IntegrationMapping: QuickBooksIntegrationMapping;
}
export interface QuickBooksExternalConfiguration {
	Accounts: IntegrationFundViewModel[];
	Classes: IntegrationFundViewModel[];
	Locations: IntegrationFundViewModel[];
}
export interface IntegrationFundViewModel {
	Key: string;
	Code: string;
	Name: string;
	ParentFundKey: string;
}
export interface IntegrationFundsInfo {
	DisplayName: string;
	ExternalSystemId: number;
	IntegrationFunds: IntegrationFundViewModel[];
}
export const TransactionImportApiConfig = {
	defaultBaseUrl: () => location.pathname.match(new RegExp('/pushpay/portal/\\d+', 'i'))[0],
	actions: {
		matchFunds: <IApiAction<{model: TransactionImportMatchFundsModel}, TransactionImportOperationResultModel>>{
			url: (data) => `/transactionimport/matchfunds`,
		},
		addNewFund: <IApiAction<{model: ImportNewFundModel}, TransactionImportAddNewFundResultModel>>{
			url: (data) => `/transactionimport/AddNewFund`,
		},
		getImportProgress: <IApiAction<{transactionImportId: number}, TransactionImportProgressResultModel>>{
			url: (data) => `/transactionimport/getImportProgress`,
		},
		confirmImport: <IApiAction<{model: TransactionImportConfirmRequestModel}, TransactionImportOperationResultModel>>{
			url: (data) => `/transactionimport/comfirmImport`,
		},
		cancelImport: <IApiAction<{transactionImportId: number}, TransactionImportOperationResultModel>>{
			url: (data) => `/transactionimport/cancelImport`,
		},
		deleteImport: <IApiAction<{transactionImportId: number}, TransactionImportOperationResultModel>>{
			url: (data) => `/transactionimport/deleteImport`,
		},
		loadMoreSkippedRows: <IApiAction<{model: TransactionImportLoadSkippedRowsRequestModel}, TransactionImportLoadSkippedRowsResultModel>>{
			url: (data) => `/transactionimport/loadMore`,
		},
		confirmSendEmail: <IApiAction<{transactionImportId: number}, TransactionImportOperationResultModel>>{
			url: (data) => `/transactionimport/comfirmSendEmail`,
		},
		getUploadUrl: <IApiAction<{model: TransactionImportNewUploadModel}, TransactionImportNewUploadResultModel>>{
			url: (data) => `/transactionimport/uploadurl`,
		},
		reportUploadStatistics: <IApiAction<{transactionImportId: number, fileName: string, fileSize: number, uploadTimeInMs: number, successful: boolean, message: string}, boolean>>{
			url: (data) => `/transactionimport/uploadstats`,
		},
		getImportUploadStatus: <IApiAction<{transactionImportId: number}, TransactionImportUploadStatusResultModel>>{
			url: (data) => `/transactionimport/upload-status`,
		},
		createImport: <IApiAction<{model: TransactionImportCreateRequestModel}, TransactionImportOperationResultModel>>{
			url: (data) => `/transactionimport/createImport`,
		},
	},
	urls: {
	},
};
/* tslint:enable:no-use-before-declare quotemark max-line-length */
// ReSharper restore InconsistentNaming
// ReSharper restore StringLiteralWrongQuotes
// ReSharper disable InconsistentNaming
// ReSharper disable StringLiteralWrongQuotes
/* tslint:disable:no-use-before-declare quotemark max-line-length */
export namespace ModelMetadata {
	export class MetadataArrayOf<T> {
		metadata: T;

		constructor(metadata: T) {
			this.metadata = metadata;
		}

		at(index: number): T {
			return this.metadata;
		}
	}

	export interface IPropertyMetadata {
		propertyName: string;
		displayName?: string;
		placeholder?: string;
		validationRules?: { [rule: string]: any };
		modelMetadata?: { [name: string] :IPropertyMetadata};
	}

	export let ImportedFundViewModel = {
		ImportFundId: {
			propertyName: "ImportFundId",
			validationRules: {
				number: {
					errorMessage: "The field ImportFundId must be a number.",
				},
				required: {
					errorMessage: "The ImportFundId field is required.",
				},
			},
		},
		ImportFundName: {
			propertyName: "ImportFundName",
		},
		PushpayFundId: {
			propertyName: "PushpayFundId",
			validationRules: {
				number: {
					errorMessage: "The field PushpayFundId must be a number.",
				},
				required: {
					errorMessage: "Please select or create a fund",
				},
			},
		},
		FundMatchStatus: {
			propertyName: "FundMatchStatus",
			validationRules: {
				required: {
					errorMessage: "The FundMatchStatus field is required.",
				},
			},
		},
	};
	export let ImportNewFundModel = {
		FundName: {
			propertyName: "FundName",
			displayName: "Fund name",
			validationRules: {
				length: {
					errorMessage: "Please enter a name 50 characters or fewer",
					parameters: {
						max: 50,
					},
				},
				required: {
					errorMessage: "Please enter a fund name",
				},
			},
		},
		Code: {
			propertyName: "Code",
			displayName: "Code (optional)",
			validationRules: {
				length: {
					errorMessage: "Please enter a code 128 characters or fewer",
					parameters: {
						max: 128,
					},
				},
				skipsanitization: {},
			},
		},
		TaxDeductible: {
			propertyName: "TaxDeductible",
			displayName: "Tax deductible",
			validationRules: {
				required: {
					errorMessage: "Please choose whether the fund should be tax deductible or not",
				},
			},
		},
		Notes: {
			propertyName: "Notes",
			displayName: "Notes (optional)",
			validationRules: {
				length: {
					errorMessage: "Please enter notes 256 characters or fewer",
					parameters: {
						max: 256,
					},
				},
			},
		},
		IntegrationFundMatches: {
			propertyName: "IntegrationFundMatches",
		},
		QuickBooksIntegrationMapping: {
			propertyName: "QuickBooksIntegrationMapping",
			validationRules: {
				skipsanitization: {},
			},
		},
	};
	export let TransactionImportCreateRequestModel = {
		ImportType: {
			propertyName: "ImportType",
			displayName: "Data format",
			validationRules: {
				required: {
					errorMessage: "Please select a data format",
				},
			},
		},
		MerchantId: {
			propertyName: "MerchantId",
			displayName: "Listing",
			validationRules: {
				number: {
					errorMessage: "The field Listing must be a number.",
				},
				required: {
					errorMessage: "Please select a listing",
				},
			},
		},
	};
}
/* tslint:enable:no-use-before-declare quotemark max-line-length */
// ReSharper restore InconsistentNaming
// ReSharper restore StringLiteralWrongQuotes
