import * as React from 'react';
import * as styles from './image-upload-error-modal.less';
import { ImageKind } from '../../branding-settings-generated';
import { getValidationMessage } from '../../editor-form/branding-settings-form';

export interface IModalDialogProps {
	errorMessage?: string;
	className?: string;
	imageKind: ImageKind;
}

export class ImageUploadErrorModal extends React.Component<IModalDialogProps> {
	render() {
		const { className, errorMessage, imageKind } = this.props;
		const classNames = `modal fade ${className ? className : ''}`;

		return (
			<div className={classNames} tabIndex={-1} role="dialog">
				<div className="modal-dialog" role="document">
					<div className="modal-content" data-pp-at-target="Modal">
						<button type="button" className="close" data-dismiss="modal" aria-label="Close" />
						<div className="modal-header">
							<p className={styles.errorContent} data-pp-at-target="image validation message">
								<h1>Invalid image</h1>
								{this.getContent(errorMessage, imageKind)}
							</p>
							<div className={styles.imageErrorExample}>
								{this.getImageExample(imageKind)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
	private getImageExample(imageKind: ImageKind) {
		switch (imageKind) {
			case ImageKind.EnhancedLogo:
				return <img src="/Content/LoggedInWeb/assets/images/branding-settings/branded-logo-example.png" alt="example image" />;
			case ImageKind.Favicon:
				return <img src="/Content/LoggedInWeb/assets/images/branding-settings/square-logo-example.png" alt="example image" />;
			case ImageKind.HeroImage:
				return <img src="/Content/LoggedInWeb/assets/images/branding-settings/background-image-example.png" alt="example image" />;
			case ImageKind.BackdropImage:
				return <img src="/Content/LoggedInWeb/assets/images/branding-settings/backdrop-image-example.png" alt="example image" />;
		}
	}

	private getContent(errorMessage: string, imageKind: ImageKind) {
		return errorMessage || getValidationMessage(imageKind);
	}
}
