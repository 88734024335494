import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { PaymentMethod, PaymentMethodUiType } from './payment-entry-model';
import { FormControlAmount } from '../../../components/form-controls/form-control-amount';
import { observer } from 'mobx-react';
import { isFunction } from '../../../utils/is-function';
import { ValidationMessage } from '../../../components/form-controls/validation-message';
import { IHaveValidatableFormContext, injectValidationFormContext, getFieldError } from '../../../components/form-controls/validatable-form-context';
import { reaction } from 'mobx';
import { Formatter } from '../../../helpers/formatter';
import { VirtualTerminalFormViewModel } from '../form/virtual-terminal-form-view-model';
import { injectAnalytics, recordEvent, WithAnalytics } from '../../../analytics';
import { ValidationHelper } from '../../../helpers/validationhelper';

const elementName = 'PaymentEntryAmount';
let nextAmountComponentId = 0;

export interface IPaymentEntryAmountProps extends IHaveValidatableFormContext {
	vm: VirtualTerminalFormViewModel;
}

@injectAnalytics
@injectValidationFormContext
@observer
export class PaymentEntryAmount extends React.Component<WithAnalytics & IPaymentEntryAmountProps, {}> {
	private amountElement: HTMLInputElement;
	private unsubscribe: Function;
	private componentId = `${nextAmountComponentId++}`;

	UNSAFE_componentWillMount() {
		this.unsubscribe = reaction(() => this.props.vm.amountFocused, () => {
			this.updateElementFocused();
		});
	}

	componentDidMount() {
		this.updateElementFocused();
	}

	componentWillUnmount() {
		if (isFunction(this.unsubscribe)) {
			this.unsubscribe();
		}
	}

	render() {
		const { amountFocused: focused, paymentEntry: { amount: value, paymentMethod }, listingStore: { selectedListingConfiguration } } = this.props.vm;
		const validationError = getFieldError(this.props, elementName);
		let { PaymentMin: min, PaymentMax: max } = selectedListingConfiguration;

		// sometimes merchant config can have the minimum value of 0
		// it's going to fail during the server-side validation because 0 isn't a valid amount for payment
		// let's use the minimum value of 0.01 in those cases
		min = Math.max(min, 0.01);

		return (
			<label className={`panel-full-width-control vt-form-amount-container${focused ? ' panel-full-width-control-focused' : ''}`}>
				<div>{this.getAmountLabel(paymentMethod)}</div>
				<div className="vt-form-payment-amount" data-pp-at-target="vt-form-payment-amount">
					<sup className={value !== null ? '' : `as-placeholder${validationError ? '-validation-error' : ''}`}>$</sup>
					<FormControlAmount name={elementName}
						uniqueSuffix={this.componentId}
						value={value}
						placeholder="0.00"
						onBlur={this.handleBlur}
						onFocus={this.handleFocus}
						readonly={this.disabled}
						refCallback={this.focusIfHasFocusedState}
						validationRules={this.getValidationRules(value, min, max, paymentMethod)}
						onChangeHandler={this.handleAmountChange}
						acceptanceTestTargetId="amount"/>
				</div>
				<ValidationMessage elementName={elementName} />
			</label>
		);
	}

	private getValidationRules(value: number, min: number, max: number, paymentMethod: PaymentMethod) {
		if (paymentMethod.type === PaymentMethodUiType.NonCash) {
			return null;
		}

		if(!paymentMethod.isProcessed) {
			const amountValidationParams = {
				min: 0,
				max: 999999,
			};

			min = amountValidationParams.min;
			max = amountValidationParams.max;
		}

		const usePaymentMax = ValidationHelper.paymentMethodTypeHasMaxAmountLimit(paymentMethod.type);

		if (value && (value < min || (usePaymentMax && value > max))) {
			return this.getRangeValidationRule(min, max);
		}
		return this.requiredValidationRule;
	}

	private getRangeValidationRule(min: number, max: number) {
		return {
			nevervalid: {
				errorMessage: `Please enter a value between $${Formatter.formatNumberForDisplay(min)} and $${Formatter.formatNumberForDisplay(max)}`
			}
		};
	}

	private get requiredValidationRule() {
		return {
			required: {
				errorMessage: 'Please enter an amount'
			}
		};
	}

	private get disabled() {
		return this.props.vm.paymentEntryDisabled || this.props.vm.isSplit;
	}

	private focusIfHasFocusedState = (element) => {
		this.amountElement = ReactDOM.findDOMNode(element) as HTMLInputElement;
	}

	private handleAmountChange = (amount: number) => {
		if (this.disabled) {
			return;
		}

		this.props.vm.handleAmountChange(amount);
	}

	private handleBlur = () => {
		const { vm, analytics } = this.props;
		if (NewFeatures.SetupPinpointAnalytics && analytics && vm.paymentEntry.amount) {
			const { feature, subFeature } = analytics;
			recordEvent({ feature, subFeature, eventTypeLabel: 'completeAmountField' });
		}
		vm.setAmountFocused(false);
	}

	private handleFocus = () => {
		this.props.vm.setAmountFocused(true);
	}

	private updateElementFocused() {
		if (this.props.vm.amountFocused) {
			this.amountElement.focus();
		} else {
			this.amountElement.blur();
		}
	}

	private getAmountLabel(paymentMethod: PaymentMethod) {
		if(paymentMethod.type === PaymentMethodUiType.NonCash) {
			return 'Amount (optional)';
		}
		return 'Amount';
	}
}
