import {
	PreconfiguredGivingLinkViewModel as GeneratedViewModel,
	PreconfiguredGivingLinkMerchantListing,
	FundInfoViewModel,
	FundVisibility,
	GiftType,
	FrequencyCode,
	RecurringEndType,
	ModelMetadata,
	Language,
	LanguageOptions,
	EmbeddedFormViewModelResult,
	EmbeddedRedirectType
} from './preconfigured-giving-links-generated';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import { preconfiguredGivingLinksDataService } from './preconfigured-giving-links-data-service';
import { PaymentLabel } from 'loggedinweb-generated';
import { ModalDialogCommander } from '../components/modal-dialog-commander';

export enum LinkType {
	PCGLAndQRCode = 0, EmbeddedForm = 1,
}

export class PreconfiguredGivingLinkViewModel {
	@observable embeddedRedirectType: EmbeddedRedirectType = EmbeddedRedirectType.None;
	@observable EmbeddedRedirectButtonUrl: string = '';
	@observable EmbeddedRedirectButtonText: string = '';
	@observable embeddedRedirectTextIsInvalid: boolean = true;
	@observable embeddedRedirectUrlIsInvalid: boolean = true;
	@observable formGenerationInfo: EmbeddedFormViewModelResult;
	@observable linkType: LinkType = LinkType.PCGLAndQRCode;
	@observable generatedForm: string;
	@observable generatedUrl: string;
	@observable generatedQrCodeUrl: string;
	@observable isGeneratingQrCode: boolean;
	@observable displayQrCodeLogo: boolean;
	@observable selectedListing: string;
	@observable selectedFund: string;
	@observable selectedFundName: string;
	@observable amount: number;
	@observable isFixedAmount: boolean;
	@observable amountIsInvalid: boolean;
	@observable giftType: GiftType;
	@observable giftTypeString: string;
	@observable recurringVisibility: boolean;
	@observable frequencyCode: FrequencyCode;
	@observable recurringStartDate: Date;
	@observable recurringEndType: RecurringEndType;
	@observable recurringEndDate: Date;
	@observable recurringMinEndDate: Date;
	@observable recurringEndOccurrences: number;
	@observable redirectUrl: string;
	@observable language: Language;
	@observable note: string;
	@observable funds: FundInfoViewModel[];
	@observable fundVisibility: FundVisibility;
	@observable validationErrors: { [key: string]: string };
	@observable redirectUrlIsInvalid: boolean;
	@observable embeddedGivingFormGenerationFlag: boolean;
	@observable embeddedGivingScriptGenerationFlag: boolean;
	listings: PreconfiguredGivingLinkMerchantListing[];
	recurringMinStartDate: Date;
	oneDayInSeconds: number;
	payerSpecificRecurringEndDateEnabled: boolean;
	paymentLabel: PaymentLabel;
	isEntitledToLanguageSelector: boolean;
	availableRecurringFrequencies: FrequencyCode[];
	preconfiguredGivingLinkHelpUrl: string;
	embeddedWidgetHelpUrl: string;

	constructor(vm: GeneratedViewModel) {
		this.oneDayInSeconds = 86400000;
		this.paymentLabel = vm.PaymentLabel;
		this.isEntitledToLanguageSelector = vm.IsEntitledToLanguageSelector;
		this.availableRecurringFrequencies = vm.AvailableRecurringFrequencies;
		this.generatedUrl = vm.GeneratedUrl;
		this.listings = vm.MerchantListings.filter((x) => x.ListingIsActive);
		this.selectedListing = this.getAvailableListingOptions()[0] && this.getAvailableListingOptions()[0].id;
		this.payerSpecificRecurringEndDateEnabled = vm.PayerSpecifiedRecurringEndDateEnabled;
		this.getFundsOfCurrentListing();
		this.selectedFund = '';
		this.selectedFundName = '';
		this.fundVisibility = FundVisibility.Show;
		this.giftType = GiftType.Default;
		this.giftTypeString = 'D';
		this.recurringVisibility = true;
		this.frequencyCode = FrequencyCode.Weekly;
		this.recurringStartDate = vm.MerchantListings[0] && vm.MerchantListings[0].ListingDateTime;
		this.recurringMinStartDate = this.recurringStartDate;
		this.recurringMinEndDate = new Date(this.recurringMinStartDate.getTime() + this.oneDayInSeconds);
		this.recurringEndType = RecurringEndType.Never;
		this.recurringEndDate = null;
		this.recurringEndOccurrences = null;
		this.redirectUrl = '';
		this.language = this.defaultLanguageOption();
		this.note = '';
		this.validationErrors = {};
		this.redirectUrlIsInvalid = false;
		this.amountIsInvalid = false;
		this.isGeneratingQrCode = false;
		this.displayQrCodeLogo = true;
		this.preconfiguredGivingLinkHelpUrl = vm.PreconfiguredGivingLinkHelpUrl;
		this.embeddedWidgetHelpUrl = vm.EmbeddedWidgetHelpUrl;
		this.embeddedGivingFormGenerationFlag = vm.EmbeddedGivingFormGenerationFlag
		this.embeddedGivingScriptGenerationFlag = vm.EmbeddedGivingScriptGenerationFlag
		reaction(
			() => ({
				displayQrCodeLogo: this.displayQrCodeLogo,
				selectedListing: this.selectedListing,
				selectedFund: this.selectedFund,
				amount: this.amount,
				isFixedAmount: this.isFixedAmount,
				giftType: this.giftType,
				recurringVisibility: this.recurringVisibility,
				frequencyCode: this.frequencyCode,
				recurringStartDate: this.recurringStartDate,
				recurringEndType: this.recurringEndType,
				recurringEndDate: this.recurringEndDate,
				recurringMinEndDate: this.recurringMinEndDate,
				recurringEndOccurrences: this.recurringEndOccurrences,
				note: this.note,
				funds: this.funds,
				fundVisibility: this.fundVisibility,
				language: this.language,
			}),
			() =>
				runInAction(() => {
					this.generatedUrl = '';
					this.generatedQrCodeUrl = '';
				})
		);
	}

	@action
	generateUrlWithQrCodeLink = async () => {
		this.isGeneratingQrCode = true;
		try {
			let urlWithQrCode = await preconfiguredGivingLinksDataService.generateUrlWithQrCodeLink({
				request: {
					MerchantHandle: this.selectedListing,
					FundName: this.selectedFund,
					FundVisibility: this.fundVisibility,
					Amount: this.amount,
					IsFixedAmount: this.isFixedAmount,
					GiftType: this.giftType,
					RecurringVisibility: this.recurringVisibility,
					FrequencyCode: this.frequencyCode,
					StartDate: this.recurringStartDate,
					RecurringEndType: this.recurringEndType,
					EndDate: this.recurringEndDate,
					RecurringEndOccurrences: this.recurringEndOccurrences,
					RedirectUrl: this.redirectUrl,
					Language: this.language,
					Note: this.note,
					DisplayQrCodeLogo: this.displayQrCodeLogo,
				},
			});

			this.generatedQrCodeUrl = urlWithQrCode.QrCodeGenerationUrl;
			this.generatedUrl = urlWithQrCode.ShortLink;
		} catch (error) {
			this.generatedUrl = '';
			this.generatedQrCodeUrl = '';
			this.isGeneratingQrCode = false;

			if (this.embeddedGivingFormGenerationFlag) {
				this.resetForm();
			}

			if (error.validationErrors && error.validationErrors.AmountRangeError) {
				this.amountIsInvalid = true;
				if (this.validationErrors['Amount']) {
					delete this.validationErrors['Amount'];
				}
				this.validationErrors = {
					['Amount']: error.validationErrors.AmountRangeError,
				};
			} else if (error.validationErrors && error.validationErrors.RedirectUrlError) {
				this.redirectUrlIsInvalid = true;
				this.validationErrors = {
					['RedirectUrl']: error.validationErrors.RedirectUrlError,
				};
			} else if (error.userError) {
				ModalDialogCommander.error(error.userError, '');
			} else {
				ModalDialogCommander.showStandardErrorMessage();
			}
		}
	};

	@action
	generateEmbeddedForm = async () => {
		this.isGeneratingQrCode = true;
		this.formGenerationInfo = null;

		if (this.embeddedRedirectType === EmbeddedRedirectType.None) {
			this.EmbeddedRedirectButtonUrl = '';
			this.EmbeddedRedirectButtonText = '';
		}

		try {
			const formInfo = await preconfiguredGivingLinksDataService.getEmbeddedFormInfo({
				request: {
					MerchantHandle: this.selectedListing,
					EmbeddedRedirectButtonUrl: this.EmbeddedRedirectButtonUrl,
					EmbeddedRedirectType: this.embeddedRedirectType,
					EmbeddedRedirectButtonText: this.EmbeddedRedirectButtonText
				},
			});

			this.formGenerationInfo = formInfo;
			this.isGeneratingQrCode = false;
		} catch (error) {
			this.isGeneratingQrCode = false;

			if (error.validationErrors) {
				if (error.validationErrors.EmbeddedRedirectUrlError) {
					this.embeddedRedirectUrlIsInvalid = true;
					this.validationErrors = {
						['EmbeddedRedirectButtonUrl']: error.validationErrors.EmbeddedRedirectUrlError,
					};
				}

				if (error.validationErrors.EmbeddedRedirectTextError) {
					this.embeddedRedirectTextIsInvalid = true;
					this.validationErrors = {
						['EmbeddedRedirectButtonText']: error.validationErrors.EmbeddedRedirectTextError,
					};
				}
			} else {
				ModalDialogCommander.showStandardErrorMessage();
			}
		}
	};

	@computed
	get isEmbeddedMode() {
		return this.linkType === LinkType.EmbeddedForm;
	}

	@action.bound
	resetForm() {
		this.EmbeddedRedirectButtonText = '';
		this.EmbeddedRedirectButtonUrl = '';
	}

	@action.bound
	setLinkType(type: LinkType) {
		this.linkType = type;
	}

	@action.bound
	changeRedirectType(type: EmbeddedRedirectType) {
		this.embeddedRedirectType = type;
		this.formGenerationInfo = null;
	}

	@action.bound
	changeEmbeddedRedirectText(text: string) {
		this.EmbeddedRedirectButtonText = text;
		this.formGenerationInfo = null;
	}

	@action.bound
	changeEmbeddedRedirectUrl(url: string) {
		this.EmbeddedRedirectButtonUrl = url;
		this.formGenerationInfo = null;
	}

	@action.bound
	onQrCodeGenerationComplete() {
		this.isGeneratingQrCode = false;
	}

	@action.bound
	updateValidationErrors(validationErrors: { [key: string]: string }) {
		this.validationErrors = validationErrors;
	}

	@action
	changeSelectListing = (listingHandle: string) => {
		this.selectedListing = listingHandle;
		if (this.embeddedGivingFormGenerationFlag) {
			this.formGenerationInfo = null;
		}
	};

	@action
	getFundsOfCurrentListing = async () => {
		if (this.listings && this.listings.length) {
			this.funds = await preconfiguredGivingLinksDataService.getAllFundsForCurrentListing({
				listingId: this.listings[0].ListingId,
			});
		}
	};

	@action
	changeSelectedFund = (selectedFund: string) => {
		this.selectedFund = selectedFund;

		if (this.selectedFund == '') {
			this.fundVisibility = FundVisibility.Show;
		} else {
			this.selectedFundName = this.funds.filter((fund) => fund.FundKey == this.selectedFund)[0].FundName;
		}
	};

	@action
	changeFundVisibility = (fundVisibility: FundVisibility) => {
		this.fundVisibility = fundVisibility;
	};

	changeAmount = (amount: number) => {
		if (amount) {
			this.amountIsInvalid = false;
		}

		this.amount = amount;
	};

	@action
	changeIsFixedAmount = (isFixedAmount: boolean) => {
		this.isFixedAmount = isFixedAmount;
	};

	@action
	changeGiftType = (giftType: GiftType) => {
		this.giftType = giftType;

		if (giftType == GiftType.Recurring) {
			this.giftTypeString = 'R';
			this.frequencyCode = FrequencyCode.Weekly;
			this.recurringStartDate = this.recurringMinStartDate;
			this.recurringEndType = RecurringEndType.Never;
			this.recurringMinEndDate = new Date(this.recurringMinStartDate.getTime() + this.oneDayInSeconds);
			this.recurringEndDate = this.recurringMinEndDate;
		} else {
			this.giftTypeString = 'OT';
		}
	};

	@action
	changeRecurringVisibility = (recurringVisibility: boolean) => {
		this.recurringVisibility = recurringVisibility;
	};

	@action
	changeRecurringFrequency = (frequencyCode: FrequencyCode) => {
		this.frequencyCode = frequencyCode;
		if (this.frequencyCode == FrequencyCode.FirstAndFifteenth) {
			const minStartDate = this.recurringMinStartDate.getDate();
			const minStartMonth = this.recurringMinStartDate.getMonth();
			const minStartYear = this.recurringMinStartDate.getFullYear();

			this.recurringStartDate = new Date(
				minStartYear,
				minStartDate <= 15 ? minStartMonth : minStartMonth + 1,
				minStartDate <= 15 && minStartDate > 1 ? 15 : 1
			);
		}
	};

	@action
	changeStartDate = (startDate: Date) => {
		this.recurringStartDate = startDate;
		this.recurringMinEndDate = new Date(startDate.getTime() + this.oneDayInSeconds);
		if (this.recurringStartDate > this.recurringEndDate) {
			this.recurringEndDate = new Date(this.recurringStartDate.getTime() + this.oneDayInSeconds);
		}
	};

	@action
	changeRecurringEndType = (recurringEndType: RecurringEndType) => {
		this.recurringEndType = recurringEndType;

		if (recurringEndType == RecurringEndType.Date) {
			this.recurringEndDate = new Date(this.recurringStartDate.getTime() + this.oneDayInSeconds);
		}
	};

	@action
	changeEndDate = (endDate: Date) => {
		this.recurringEndDate = endDate;
	};

	@action
	changeRecurringEndOccurrences = (recurringEndOccurrence: number) => {
		if (isNaN(recurringEndOccurrence)) {
			this.recurringEndOccurrences = 1;
		} else if (recurringEndOccurrence < 1) {
			this.recurringEndOccurrences = 1;
		} else {
			this.recurringEndOccurrences = recurringEndOccurrence;
		}

		if (
			recurringEndOccurrence <
			ModelMetadata.PreconfiguredGivingLinkGenerationRequest.RecurringEndOccurrences.validationRules.range
				.parameters.max
		) {
			this.generatedUrl = '';
		}
	};

	@action
	changeRedirectUrl = (redirectUrl: string) => {
		if (redirectUrl) {
			this.redirectUrlIsInvalid = false;
		}

		this.redirectUrl = redirectUrl;
	};

	@action
	changeLanguage = (selectedLanguageCode: string) => {
		this.language = LanguageOptions[selectedLanguageCode];
	};

	getAvailableListingOptions = (): { id: string; listingName: string }[] => {
		return this.listings.map((x) => ({
			id: x.ListingHandle,
			listingName: x.ListingName,
		}));
	};

	defaultLanguageOption = (): Language => {
		if (!this.languageSelectorIsEnabled()) {
			return null;
		}

		return LanguageOptions.en;
	};

	languageSelectorIsEnabled = (): boolean => {
		return this.isEntitledToLanguageSelector;
	};

	@action
	changeNote = (note: string) => {
		this.note = note;
	};

	@action
	changeDisplayQrCodeLogo = (displayQrCodeLogo: boolean) => {
		this.displayQrCodeLogo = displayQrCodeLogo;
	};
}
