import { ScheduleImportStepViewModel, ImportStep } from '../schedule-import-step-view-model';
import { AddNewFundViewModel } from '../../../transaction-import/components/add-new-fund-dialog';
import { observable, action } from 'mobx';
import { AutocompleteStore, IAutocompleteItem } from '../../../components/autocomplete';
import { getScheduleImportDataService, ScheduleImportDataServiceAction, ScheduleImportApiConfigType, } from '../../schedule-import-data-service';
import { IDataServiceActionSubscriber } from '../../../utils/data-service';
import {
	ScheduleImportFundViewModel,
	FundMatchingViewModel,
	ModelMetadata,
	PushpayFundModel,
	ScheduleImportFundMatchStatus,
	OperationResponse,
	OperationResult,
	AddNewFundResponse,
	PaymentLabel,
} from '../../schedule-import-generated';

export class ScheduleImportFundMatchingViewModel extends ScheduleImportStepViewModel {
	readonly scheduleImportBundleId: number;
	readonly merchantId: number;
	readonly cancelUrl: string;
	readonly paymentLabel: PaymentLabel;
	readonly canAddNewFund: boolean;

	@observable
	importedFunds: ScheduleImportFundViewModel[];

	@observable
	pushpayFundStores: AutocompleteStore[];

	@observable
	autoCompleteItems: IAutocompleteItem[];

	@observable
	addNewFundViewModel: AddNewFundViewModel;

	newFundList: number[] = [];

	matchFundsSubscriber: IDataServiceActionSubscriber<ScheduleImportApiConfigType, 'matchFunds'>;
	addNewFundSubscriber: IDataServiceActionSubscriber<ScheduleImportApiConfigType, 'addNewFund'>;
	cancelImportSubscriber: IDataServiceActionSubscriber<ScheduleImportApiConfigType, 'cancelImport'>;
	
	hasCcbIntegration: boolean;

	constructor(model: FundMatchingViewModel) {
		super(
			model.PaymentLabel,
			ImportStep.Match,
			'Match your funds',
			/* tslint:disable:max-line-length */
			`Match the funds from your file with the funds in Pushpay. If a fund from your file is no longer active, you can request ${model.PaymentLabel.PayerPluralLowerCase} to select a new fund instead.`
			/* tslint:enable:max-line-length */
		);

		this.scheduleImportBundleId = model.ScheduleImportBundleId;
		this.merchantId = model.MerchantId;
		this.cancelUrl = model.CancelUrl;
		this.paymentLabel = model.PaymentLabel;

		this.importedFunds = model.ImportedFunds;

		this.autoCompleteItems = model.PushpayFunds.map(o => ({ Text: o.PushpayFundDisplayName, Value: `${o.PushpayFundId}` }));
		this.pushpayFundStores = model.ImportedFunds.map(o => (new AutocompleteStore({Items: this.autoCompleteItems, SelectedValue: `${o.PushpayFundId}`})));

		this.addNewFundViewModel = new AddNewFundViewModel(
			model.FundCodePlaceholderText,
			model.PaymentLabel.PayerPluralLowerCase,
			model.IntegrationFundsInfo,
			model.QuickBooksManageFundInfo,
			ModelMetadata.ImportNewFundModel,
			model.HasCcbIntegration,
			model.AvailableListings,
			model.MerchantId
		);
		this.canAddNewFund = model.AvailableListings && model.AvailableListings.length > 0;

		this.matchFundsSubscriber = getScheduleImportDataService().getActionSubscriberFactory('matchFunds')((action) => this.subscribeToMatchFunds(action));
		this.addNewFundSubscriber = getScheduleImportDataService().getActionSubscriberFactory('addNewFund')((action) => this.subscribeToAddNewFund(action));
		this.cancelImportSubscriber = getScheduleImportDataService().getActionSubscriberFactory('cancelImport')((action) => this.subscribeToCancelImport(action));
		
		this.hasCcbIntegration = model.HasCcbIntegration;
	}

	@action.bound
	updateAskDonorForFundChecked(rowIndex: number, isChecked: boolean) {
		if (isChecked) {
			this.importedFunds[rowIndex].FundMatchStatus = ScheduleImportFundMatchStatus.AskDonorToChoose;
			this.importedFunds[rowIndex].PushpayFundId = 0;
			this.pushpayFundStores[rowIndex].reset();
		} else {
			this.importedFunds[rowIndex].FundMatchStatus = ScheduleImportFundMatchStatus.Unmatched;
		}
	}

	@action.bound
	addNewPushayFund(newFund: PushpayFundModel) {
		if (newFund) {
			this.newFundList.push(newFund.PushpayFundId);
			this.autoCompleteItems.push({Text: newFund.PushpayFundDisplayName, Value: `${newFund.PushpayFundId}`});
		}
	}

	updateFundMappings() {
		this.importedFunds.forEach((f, index) => {
			const selectedFundId = Number(this.pushpayFundStores[index].selectedValue);

			if (f.PushpayFundId !== selectedFundId) {
				if (f.FundMatchStatus !== ScheduleImportFundMatchStatus.AskDonorToChoose) {
					f.PushpayFundId = selectedFundId;
					if (this.newFundList.indexOf(selectedFundId) >= 0) {
						f.FundMatchStatus = ScheduleImportFundMatchStatus.ManuallyMatchedToNewFund;
					} else {
						f.FundMatchStatus = ScheduleImportFundMatchStatus.ManuallyMatchedToExistingFund;
					}
				}
			}
		});
	}

	@action.bound
	initMatchFundsRequest() {
		this.updateFundMappings();
		this.actionInProgress = true;
		this.matchFundsSubscriber.initRequest({
			model: {
				ScheduleImportBundleId: this.scheduleImportBundleId,
				ImportedFunds: this.importedFunds
			},
		});
	}

	@action.bound
	initAddNewFundRequest() {
		this.addNewFundViewModel.addFundInProgress = true;
		this.addNewFundSubscriber.initRequest({
			selectedListings: this.addNewFundViewModel.selectedMerchants.map(x => Number(x)),
			model: {
				FundName: this.addNewFundViewModel.fundName,
				Code: this.addNewFundViewModel.code,
				Notes: this.addNewFundViewModel.notes,
				TaxDeductible: this.addNewFundViewModel.taxDeductible,
				IntegrationFundMatches: this.addNewFundViewModel.matchedIntegrationFunds,
				QuickBooksIntegrationMapping: this.addNewFundViewModel.matchedQuickBooksConfiguration,
			}
		});
	}

	@action.bound
	initCancelImportRequest() {
		this.actionInProgress = true;
		this.cancelImportSubscriber.initRequest({
			scheduleImportBundleId: this.scheduleImportBundleId
		});
	}

	private subscribeToMatchFunds(action: ScheduleImportDataServiceAction) {
		switch (action.type) {
			case 'request_success':
				const response =  action.response as OperationResponse;
				if (response.Result === OperationResult.Success) {
					window.location.href = response.SuccessUrl;
					return;
				} else {
					this.showErrorAlert(response.ErrorMessage);
				}
				break;
			case 'request_error':
				const validationErrors = action.error.validationErrors;
				if (validationErrors) {
					this.showValidationErrorsAlert(validationErrors);
				}
				break;
		}
		this.actionInProgress = false;
	}

	private subscribeToAddNewFund(action: ScheduleImportDataServiceAction) {
		switch (action.type) {
			case 'request_success':
				const response =  action.response as AddNewFundResponse;
				if (response.Result === OperationResult.Success) {
					this.addNewPushayFund(response.NewFund);
					this.addNewFundViewModel.closeDialog();
					this.showSuccessAlert('Your new fund has been successfully created.');
				} else {
					this.showErrorAlert(response.ErrorMessage);
				}
				break;
			case 'request_error':
				const validationErrors = action.error.validationErrors;
				if (validationErrors) {
					this.showValidationErrorsAlert(validationErrors);
				}
				break;
		}

		this.addNewFundViewModel.addFundInProgress = false;
	}

	private subscribeToCancelImport(action: ScheduleImportDataServiceAction) {
		switch (action.type) {
			case 'request_success':
				const response =  action.response as OperationResponse;
				if (response.Result === OperationResult.Success) {
					window.location.href = this.cancelUrl;
					return;
				} else {
					this.showErrorAlert(response.ErrorMessage);
				}
			break;
		}
		this.actionInProgress = false;
	}
}
